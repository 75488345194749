<template>
    <div>
    	<div class="container-fluid">
    		<div class="row justify-content-center mt-4">
                <div class="col-md-10">
                    <div class="site_title clearfix text-center" style="padding: 60px;">
                        <img src="/images/logo.png" class="img_logo pr-2" style="width: 75%;">
                    </div>
                </div>
            </div>

            <!-- navigation  -->
            <div class="row justify-content-center nav_bg">
                <div class="col-md-10">
                    <Navigation></Navigation>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <div class="clearfix">
                                <h4 class="float-left">Traineeship Programmes</h4>
                                <div class="input-group mb-3 float-right w-25">
                                    <input type="text" class="form-control rounded-0" v-model="keywordSearch" placeholder="Search Job" aria-label="Search Job" aria-describedby="basic-addon2">

                                    <div class="input-group-append rounded-0">
                                        <button class="btn btn-danger" @click="searchJob(keywordSearch)" type="button" id="basic-addon2"><i class="fas fa-search"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <template v-if="!jobs.length"> -->
                    <template v-if="isLoading">
                        <center><Stretch></Stretch></center>
                    </template>
                    <template v-if="showNoResult">
                      <center><p class="text-muted">No result found</p></center>
                    </template>
                    <template v-if="isLoaded">
                        <div class="clearfix">
                            <div v-for="job in jobs" class="w-50 float-left">
                                <!-- <div class="col-md-12"> -->
                                    <div class="card mx-2">
                                        <div class="card-body py-4">
                                            <div class="row">
                                                <div class="col-md-4 text-center">
                                                    <img :src="job.employer.photo_img==null ? '/images/default_user.png' : job.employer.photo_img" class="img-fluid customImgStyle">
                                                </div>
                                                <div class="col-md-8">
                                                    <h5>{{ job.title }}</h5>
                                                    <p class="text-muted mb-0 text-sm">By: {{ job.employer.name }}</p>
                                                    <p class="text-muted"><small>Posted {{ dateFormatter(job.created_at) }} <i>({{ dateCounter(job.created_at) }})</i></small></p>
                                                    <ul class="pl-3">
                                                        <li><small>{{ job.job_type }}</small></li>
                                                        <li><small>{{ job.career_level }}</small></li>
                                                        <li><small>{{ job.yrs_exp }}</small></li>
                                                    </ul>
                                                    <router-link :to="{ path: '/apply/'+job.slug }" class="btn btn-danger btn-sm py-0">
                                                        <small>VIEW <i class="fas fa-arrow-right"></i></small>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <!-- </div> -->
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            
            <!-- footer -->
            <pageFooter v-if="isLoaded"></pageFooter>
        </div>
    </div>
</template>
<script>
    import Navigation from '../../layout/Navigation';
    import {Stretch} from 'vue-loading-spinner'
    import pageFooter from '../../layout/Footer';

    import moment from 'moment';

    export default {
        name: "organization-profile",
        components: {
            Stretch,
            Navigation,
            pageFooter
        },
        data() {
            return {
                jobs: [],
                keywordSearch: null,
                isLoaded: false,
                isLoading: false,
                showNoResult: false
            }
        },
        created() {
            this.getLatestJobs();
        },
        methods: {
            async getLatestJobs() {

                this.isLoaded = false;
                this.isLoading = true;

                try {
                    const response = await axios.get('/api/job/fetchJobs');
                    this.jobs = response.data.jobs;
                    this.isLoaded = true;
                    this.showNoResult = false;
                    this.isLoading = false;
                } 
                catch(error) {
                    throw error;
                }
            },

            async searchJob(keyword) {

                if(!keyword) {
                    this.getLatestJobs();
                    return;
                }
                   
                this.isLoading = true;
                this.showNoResult = false;
                this.jobs = [];

                try {
                    const response = await axios.post('/api/keywordOnlySearch', {keyword});
                    
                    if(response.data.jobs.length > 0) {
                        this.isLoaded = true;
                        this.isLoading = false;
                        this.showNoResult = false;
                        this.jobs = response.data.jobs;
                    } else {
                        this.isLoading = false;
                        this.showNoResult = true;
                    }

                } 
                catch(error) {
                    throw error;
                }
            },

            dateFormatter(data) {
                return moment(data).format("MMM DD, YYYY");
            },
            dateCounter(data) {
                return moment(data,"YYYYMMDD").fromNow();
            }
        }
    }

</script>
<style scoped>
    .custom-margin {
        padding: 25px 15px 40px 15px;
    }

    /*.customImgStyle {*/
    /*    width: 50% !important;*/
    /*}*/

</style>

