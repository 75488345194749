<template>
	<div>
		<div class="card card-default">
			<div class="card-header"><h4 class="float-left">CREATE NEW JOB APPLICATION</h4></div>
			<form role="form" @submit.prevent="add()" enctype="multipart/form-data">
				<div class="card-body">
					<div class="row">
						<div class="col-md-8">
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label for="name">Job Title</label>
										<input :class="{ 'is-invalid' : errors.first('JobTItle') }" 
											v-model="job.title" 
											type="text" 
											name="JobTItle" 
											class="form-control" 
											placeholder="Job Title" 
											v-validate="'required'">
										<small class="text-danger">{{ errors.first('JobTItle') }}</small>
									</div>
									<div class="form-group">
										<label for="name">Description</label>
										<wysiwyg v-model="job.description" 
											:class="{ 'danger_border' : errors.first('description') }"
											v-validate="'required'" 
											name="description" 
											placeholder="What are the job descriptions" />
										<small class="text-danger">{{ errors.first('description') }}</small>
									</div>
									<div class="form-group">
										<label for="name">Job Responsibilities</label>
										<wysiwyg v-model="job.responsibilities"
											:class="{ 'danger_border' : errors.first('responsibilities') }"
											v-validate="'required'" 
											name="responsibilities" 
											placeholder="What are the job responsibilitiess" />
										<small class="text-danger">{{ errors.first('responsibilities') }}</small>
									</div>
									<div class="form-group">
										<label for="name">Qualifications</label>
										<wysiwyg v-model="job.qualifications"
											:class="{ 'danger_border' : errors.first('qualifications') }"
											v-validate="'required'" 
											name="qualifications" 
											placeholder="Enter qualifications" />
										<small class="text-danger">{{ errors.first('qualifications') }}</small>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="form-group row">
										<div class="col-md-3">
											<label class="col-form-label">Career Level</label>
										</div>
										<div class="col-md-9">
											<multiselect v-model="job.career_level" 
												name="career_level"
												v-validate="'required'"
												:class="{ 'danger_border' : errors.first('career_level') }"
												:options="careerLevels" 
												:searchable="false" 
												:close-on-select="true" 
												:show-labels="false" placeholder="Career level requirement">
											</multiselect>
											<small class="text-danger">{{ errors.first('career_level') }}</small>
										</div>
									</div>
									<div class="form-group row">
										<div class="col-md-3">
											<label class="col-form-label">Years Exp.</label>
										</div>
										<div class="col-md-9">
											<multiselect v-model="job.yrs_exp" 
												name="years_experience"
												v-validate="'required'"
												:class="{ 'danger_border' : errors.first('years_experience') }"
												:options="yrsExp" 
												:searchable="false" 
												:close-on-select="true" 
												:show-labels="false" placeholder="Years experience requirements">
											</multiselect>
											<small class="text-danger">{{ errors.first('years_experience') }}</small>
										</div>
									</div>
									<div class="form-group row">
										<div class="col-md-3">
											<label class="col-form-label">Job Type</label>
										</div>
										<div class="col-md-9">
											<multiselect v-model="job.job_type" 
												name="Job_Type"
												v-validate="'required'"
												:class="{ 'danger_border' : errors.first('Job_Type') }"
												:options="jobType" 
												:searchable="false" 
												:close-on-select="true" 
												:show-labels="false" placeholder="Please select job type">
											</multiselect>
											<small class="text-danger">{{ errors.first('Job_Type') }}</small>
										</div>
									</div>
									<div class="form-group row">
										<div class="col-md-3">
											<label class="col-form-label">Job Location</label>
										</div>
										<div class="col-md-9">
											<input :class="{ 'is-invalid' : errors.first('location') }" 
												v-model="job.location" 
												type="text" 
												name="location" 
												class="form-control" 
												placeholder="Location of the job" 
												v-validate="'required'">
											<small class="text-danger">{{ errors.first('location') }}</small>
										</div>
									</div>
									<div class="form-group row">
										<div class="col-md-3">
											<label class="col-form-label">Salary Offer</label>
										</div>
										<div class="col-md-9">
											<div class="input-group">
												<div class="input-group-prepend">
													<span class="input-group-text"><i class="fas fa-dollar-sign"></i></span>
												</div>
												<input :class="{ 'is-invalid' : errors.first('salary') }" 
												v-model="job.salary" 
												type="text" 
												name="salary" 
												class="form-control" 
												placeholder="Salary offer (you can input text or figure)" 
												v-validate="">
											</div>
											<small class="text-danger">{{ errors.first('salary') }}</small>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group row">
										<div class="col-md-4">
											<label class="col-form-label">Schedule Post</label>
										</div>
										<div class="col-md-8">
											<input :class="{ 'is-invalid' : errors.first('schedule_post') }" 
												v-model="job.schedule_post" 
												type="date" 
												name="schedule_post" 
												class="form-control" 
												v-validate="''">
											<small class="text-danger">{{ errors.first('schedule_post') }}</small>
										</div>
									</div>
									<div class="form-group row">
										<div class="col-md-4">
											<label class="col-form-label">Post valid until</label>
										</div>
										<div class="col-md-8">
											<input :class="{ 'is-invalid' : errors.first('valid_until') }" 
												v-model="job.valid_until" 
												type="date" 
												name="valid_until" 
												class="form-control" 
												v-validate="''">
											<small class="text-danger">{{ errors.first('valid_until') }}</small>
										</div>
									</div>
									<div class="form-group row">
										<div class="col-md-4">
											<label class="col-form-label">Submit application</label>
										</div>
										<div class="col-md-8">
											<input :class="{ 'is-invalid' : errors.first('emailToApply') }" 
												v-model="job.emailToSubmitResume" 
												type="email" 
												name="emailToApply" 
												class="form-control" 
												placeholder="Email Address where to submit application" 
												v-validate="'required|email'">
											<small class="text-danger">{{ errors.first('emailToApply') }}</small>
										</div>
									</div>
									<div class="form-group row">
										<div class="col-md-4">
											<label class="col-form-label">Searcheable</label>
										</div>
										<div class="col-md-8">
											<div class="btn-group btn-group-toggle" data-toggle="buttons">
												<label class="btn btn-secondary" :class="{'btn-success':job.is_searcheable==1}">
													<input v-model="job.is_searcheable" value="1" type="radio"> YES
												</label>
												<label class="btn btn-secondary" :class="{'btn-danger':job.is_searcheable==0}">
													<input v-model="job.is_searcheable" value="0" type="radio"> NO
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<h5 class="border-bottom border-light pb-3">Job Classification</h5>
								<div class="card-body table-responsive p-0" style="height: 350px;">
									<ul class="list-group list-group-flush">
										<li class="list-group-item custom-list-style pl-1" v-for="jobClass in jobclass" :key="jobClass.id">
											<input type="radio" @change="changeJobClass(job.clasification)" 
											v-model="job.clasification" :value="jobClass.name"/>
												{{ jobClass.name.length > 30 ? jobClass.name.substring(0,30) + '...' : jobClass.name }}
										</li>
									</ul>
								</div>
							</div>

							<div v-if="job.clasification!==null" class="form-group">
								<h5 class="border-top border-light py-3">Sub-classification for <i>{{ job.clasification }}</i></h5>
								<multiselect 
									v-model="job.selectedSubClass" 
									:options="job.subclasification" 
									:multiple="true" 
									:close-on-select="false" 
									:clear-on-select="true" 
									:preserve-search="true" placeholder="Select Job Sub Classification" label="name" track-by="name" :preselect-first="false">
								</multiselect>
							</div>

							<div class="form-group">
								<h5 for="exampleInputFile" class="border-top border-light py-3 font">Add image</h5>
								<div v-if="!uploadfilepath.length" class="input-group">
									<div class="custom-file">
										<input @change="upload_photo()" accept="image/*" type="file" class="form-control" id="exampleInputFile">
										<label class="custom-file-label" for="exampleInputFile">Choose file</label>
									</div>
								</div>
								<button v-else class="btn btn-primary" type="button" @click="uploadfilepath=''"><i class="fas fa-times"></i> Cancel</button>

								<img v-if="uploadfilepath.length" :src="uploadfilepath" class="mt-2" style="width:100%" />

							</div>
						</div>
					</div>
					<div class="row" v-if="is_show_error">
						<div class="col-md-8">
							<hr />
							<div class="jumbotron pt-4 pb-2 pl-2">
								<ul><li v-for="error in errorChecker" class="text-danger">{{ error }}</li></ul>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<button type="submit" class="btn btn-primary"><i class="fas fa-thumbs-up"></i> POST</button>
					<button type="reset" class="btn btn-dark" @click="resetForm()">RESET</button>
					<router-link :to="{ path: '/jobs' }" class="btn btn-outline-dark float-right"><i class="fas fa-angle-left"></i> BACK</router-link>
				</div>
			</form>
		</div>	
	</div>
</template>

<script>
	
	import CircleSpin from 'vue-loading-spinner/src/components/Circle'
	import moment from 'moment'
	import Multiselect from 'vue-multiselect'
	import { mapGetters } from 'vuex'
	
	export default {
		name: 'employer-new-job',
		components: {
			CircleSpin,
			Multiselect
		},
		data() {

			return {
				uploadfilepath: '',
				photo_img: null,
				is_show_error: false,
				errorChecker: [],
				job: {
					title: null,
					description: null,
					responsibilities: null,
					qualifications: null,
					career_level: null,
					yrs_exp: null,
					job_type: null,
					salary: null,
					location: null,
					schedule_post: null,
					valid_until: null,
					is_searcheable: 1,
					clasification: null,
					emailToSubmitResume: null,
					subclasification: [],
					selectedSubClass: []
				},
				careerLevels: ['Not Applicable', 'Entry-level', 'Intermediate', 'Mid-level', 'Senior or executive-level'],
				yrsExp: ['1-2 Years Experience', '2-4 Years Experience', '4-10 Years Experience', '10+ years Experience'],
				jobType: ['Full-time', 'Part-time', 'Volunteer', 'Trainee', 'Internship']
			}

		},
		created() {
			this.$store.dispatch('fetchJobClass');
			if(this.$route.params.id!=this.currentUser.employer_id) {
				this.$router.push('/employer/' + this.currentUser.employer_id + '/job/new');
			} 
		},
		computed: {
			...mapGetters(['jobclass']),
			currentUser() {
				return this.$store.getters.currentUser;
			}
		},
		methods: {
			resetForm() {
				location.reload();
			},
			changeJobClass(selectedJC) {
				this.job.selectedSubClass = [];
				this.job.subclasification = [];
				let currentObject = this;
				this.$store.getters.jobclass.forEach( function(item) {
					if(item.name==selectedJC) {
						currentObject.job.subclasification = item.sub_classes;
					}
				})
			},
            upload_photo(e) {
                this.photo_img = event.target.files[0];
                this.uploadfilepath = window.URL.createObjectURL(event.target.files[0]);
            },
            add() {

            	let get_subClass = [];
            	let finalJob = {};
				let save_subClass = '';

				if(this.job.selectedSubClass.length) {
					this.job.selectedSubClass.forEach( function(item) {
						get_subClass.push({'id':item.id, 'name':item.name})
					});
					save_subClass = JSON.stringify(get_subClass);
				}

				finalJob = {
					'title': this.job.title,
					'description': this.job.description,
					'responsibilities': this.job.responsibilities,
					'qualifications': this.job.qualifications,
					'career_level': this.job.career_level,
					'yrs_exp': this.job.yrs_exp,
					'job_type': this.job.job_type,
					'salary': this.job.salary,
					'location': this.job.location,
					'schedule_post': this.job.schedule_post,
					'valid_until': this.job.valid_until,
					'is_searcheable': this.job.is_searcheable,
					'clasification': this.job.clasification,
					'emailToSubmitResume': this.job.emailToSubmitResume,
					'selectedSubClass': save_subClass
				};

				const formData = new FormData();
				formData.append('photo_img', this.photo_img);
				formData.append('user_id', this.currentUser.id);
				formData.append('employer_id', this.$route.params.id);
				formData.append('new_job', JSON.stringify(finalJob));

				this.$validator.validateAll()
					.then((result) => {
						const config = { "headers": 
											{'content-type': 'multipart/form-data', "Authorization": "Bearer "+this.currentUser.token}	
				                		}
						if(!this.errors.any()) 
						{
					        axios.post('/api/job/add', formData, config)
								.then((response) => {
									if(response.data.errors) {
										this.is_show_error = true;
										this.errorChecker = response.data.errors;
									}
									else {
										this.$swal.fire({
											position: 'top-end',
											icon: 'success',
											title: 'New job added successfully',
											showConfirmButton: false,
											timer: 1500
										});
										setTimeout(() => this.$router.push('/job/'+response.data.job.slug), 1500);
									}
								})
								.catch((error) => {
									console.log(error);
								})

						}
						else {
							this.is_show_error = true;
							this.errorChecker = this.errors.all();
						}

					})
					.catch((errors) => {
						console.log(errors);
					});

            }
		}
	}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
	.danger_border {
		border: 1px solid red !important;
	}
	.custom-list-style:hover {
		background: #e9ecef !important;
		cursor: pointer !important;
	}
</style>