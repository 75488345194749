<template>
	<div>
		<div class="card">
			<div class="card-header">
				<strong>Job Classification</strong>
			</div>
			<div class="card-body table-responsive p-0" style="height: 300px;">
				<ul class="list-group list-group-flush">
					<li class="list-group-item custom-list-style" v-for="jobClass in jobclass" :key="jobClass.id">
						<input type="radio" 
							@change="changeJobClass(jobClass)" 
							v-model="jobclassification" 
							:value="jobClass.name" 
							:checked="jobClass.name==job_class.clasification" />
							{{ jobClass.name.length > 30 ? jobClass.name.substring(0,30) + '...' : jobClass.name }}
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<p class="mt-2 pl-1"><strong>Sub-classification</strong></p>
				<multiselect v-model="jobSubclassification" :options="displayCurrentJobClasses" :multiple="true" :close-on-select="true" :clear-on-select="true" :preserve-search="true" placeholder="Select Job Sub Classification" label="name" track-by="name" :preselect-first="false">
				</multiselect>
				<button @click="updateJobSubClass(jobclassification, jobSubclassification)" class="btn btn-success btn-sm mt-4" type="button"><i class="fas fa-save"></i> UPDATE</button>
				<button v-if="is_undoBtnShow" @click="undoJobSubClass()" class="btn btn-dark btn-sm mt-4" type="button"><i class="fas fa-redo-alt"></i> UNDO</button>
			</div>
		</div>
	
	</div>
</template>
<script>
	
	import { mapGetters } from 'vuex'
	import Multiselect from 'vue-multiselect'

	export default {
		name: 'update-job-classification',
		props: ['job_class'],
		components: { Multiselect },
		data() {
			return {
				jobclassification: null,
				jobSubclassification: [],
				jobSubClasses: [],
				is_undoBtnShow: false
			}
		},

		created() {
			this.$store.dispatch('fetchJobClass');
			this.jobclassification = this.job_class.clasification;
			this.jobSubclassification = this.job_class.subclasification;
		},

		computed: {
			...mapGetters(['jobclass']),
			currentUser() {
				return this.$store.getters.currentUser;
			},
			displayCurrentJobClasses() {
				let currentObject = this;

				this.$store.getters.jobclass.forEach( function(item) {
					if(item.name==currentObject.jobclassification) {
						currentObject.jobSubClasses = item.sub_classes;
					}
				})
				return this.jobSubClasses;

			}
		},
		methods: {

			changeJobClass(jc) {
				this.jobclassification = jc.name;
				this.jobSubClasses = [];
				this.jobSubclassification = [];
				this.is_undoBtnShow = true;
			},

			undoJobSubClass() {
				this.jobclassification = this.job_class.clasification;
				this.jobSubclassification = this.job_class.subclasification;
			}, 
			updateJobSubClass(newClass, newSubClassSet) {
				const getFinalJobSubClass = [];
				let saveSubClass = '';
				let updatedRecord = [];

				if(newSubClassSet.length) {
					newSubClassSet.forEach( function(item) {
						getFinalJobSubClass.push({'id':item.id, 'name':item.name})
					});
					saveSubClass = JSON.stringify(getFinalJobSubClass);
				}  
				else {
					this.is_sectorEmpty = true;
				}

				updatedRecord = {
						'job_id': this.job_class.id,
						'user_id': this.currentUser.id,
						'jobClass': newClass,
						'jobSubClass': saveSubClass
					};

				axios.post('/api/job/update_jobClass', updatedRecord, {
					headers: { "Authorization": `Bearer ${this.currentUser.token}`},
				})
				.then((response) => {
					this.$swal.fire({
						position: 'top-end',
						icon: 'success',
						title: 'Record updated successfully',
						showConfirmButton: false,
						timer: 1500
					});
					this.job_class.clasification = newClass;
					this.job_class.subclasification = newSubClassSet;
					this.is_undoBtnShow = false;

				})
				.catch((error) => {
					console.log(error);
				})

			}
		}

	}

	
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
	.custom-list-style:hover {
		background: #e9ecef !important;
		cursor: pointer !important;
	}
</style>

