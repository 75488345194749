<template>
	<div>
		<div class="row">
			<div class="col-md-12">
				<div class="card card-default">
					<div class="card-header">
						<h5 class="float-left font-weight-bold text-uppercase mt-2">{{ employer.name }}</h5>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-3" style="border-right: 1px solid #ddd;">
								<UploadPhoto :recordType="'employerRecord'" :recordID="`${this.$route.params.id}`" :photo="employer.photo_img"></UploadPhoto>
								<hr />
								<h5 class="mt-4">Industry/sector</h5>
								<div class="jumbotron p-3 mb-3 rounded-0">
									<button class="btn btn-primary btn-sm m-1" 
									v-for="industry in industries" @click="removeSector(industry)">
										<small>{{industry.name}} &nbsp;<i class="fas fa-times text-white"></i></small>
									</button>
									<center v-if="!industries.length"><p class="text-muted">No sector found</p></center>
									<small v-if="is_sectorEmpty" class="text-danger">Sector must not be empty</small>
								</div>
								<div class="form-group mb-0 ml-3 mb-1 clearfix" v-for="industry in displaySectors">
									<div class="icheck-primary d-inline">
										<button class="btn btn-link p-0" @click="addSector(industry)">
											<i class="fas fa-plus"></i>
										</button>
										<label>{{ industry.name }}</label>
									</div>
								</div>
								<button :disabled="!industries.length" @click="updateEmployerSector" type="button" class="btn btn-success btn-sm btn-flat text-white">
									<i class="fas fa-save"></i> Save Changes
								</button>
							</div>
							<div class="col-md-9">
								<form class="form-horizontal" role="form">
									<div class="form-group row">
										<label class="col-md-2 col-form-label">Name</label>
										<div class="col-md-10">
											<div class="input-group">
												<input 
													v-model="companyName" 
													type="text" 
													class="form-control" 
													:class="{ 'is-invalid' : errors.first('company_name') }" 
													name="company_name" 
													v-validate="'required'"
													:disabled="isNameDisabled">
												<span class="input-group-append">
													<button @click="isNameDisabled ? isNameDisabled=false : isNameDisabled=true" type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isNameDisabled, 'btn-dark':isNameDisabled}">
														<i v-if="isNameDisabled" class="fas fa-lock"></i>
														<i v-else class="fas fa-lock-open"></i>
													</button>
													<button @click='updateRecord("name",companyName)' :disabled="isNameDisabled" type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
												</span>
											</div>
											<small class="text-danger">{{ errors.first('company_name') }}</small>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-2 col-form-label">Overview</label>
										<div class="col-md-10">
											<textarea
												v-model="companyOverview" 
												:class="{ 'is-invalid' : errors.first('overview') }" 
												name="overview" 
												v-validate="'required'"
											 	class="form-control" 
											 	rows="4" 
											 	:disabled="isOverviewDisabled">
											</textarea>
											<div class="clearfix">
												<button @click='updateRecord("overview",companyOverview)' type="button" class="btn btn-success btn-flat text-white float-right mt-1" :disabled="isOverviewDisabled"><i class="fas fa-save"></i></button>
												<button @click="isOverviewDisabled ? isOverviewDisabled=false : isOverviewDisabled=true" type="button" class="btn btn-flat text-white mr-1 mt-1 float-right" :class="{'btn-info':!isOverviewDisabled, 'btn-dark':isOverviewDisabled}">
													<i v-if="isOverviewDisabled" class="fas fa-lock"></i>
													<i v-else class="fas fa-lock-open"></i>
												</button>
												<small class="text-danger float-left mt-1">{{ errors.first('overview') }}</small>
											</div>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-2 col-form-label">Company Size</label>
										<div class="col-md-10">
											<div class="input-group">
												<select v-model="employer.comp_size" class="custom-select" @change="isCompSizeDisabled=false">
													<option value="0-10 Employees">0-10 Employees</option>
													<option value="10-50 Employees">10-50 Employees</option>
													<option value="50-100 Employees">50-100 Employees</option>
													<option value="100-500 Employees">100-500 Employees</option>
													<option value="More than 500 Employees">More than 500 Employees</option>
												</select>
												<span class="input-group-append">
													<button :disabled="isCompSizeDisabled" @click='updateRecord("comp_size",employer.comp_size)' type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
												</span>
											</div>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-2 col-form-label">Address</label>
										<div class="col-md-10">
											<div class="form-group row">
												<div class="col-md-12">
													<textarea 
														v-model="companyAddress" 
														:class="{ 'is-invalid' : errors.first('address') }" 
														name="address" 
														v-validate="'required'"
														type="text" 
														rows="2"
														class="form-control"
														:disabled="isAddressDisabled">
													</textarea>
													<small class="text-danger float-left mt-1">{{ errors.first('address') }}</small>
												</div>
											</div>
											<div class="form-group">
												<div class="row">
													<div class="col-md-6">
														<input 
															v-model="companyCity" 
															:class="{ 'is-invalid' : errors.first('city') }" 
															name="city" 
															v-validate="'required'"
															type="text" 
															rows="2"
															class="form-control"
															:disabled="isAddressDisabled">
															<small class="text-danger float-left mt-1">{{ errors.first('city') }}</small>
													</div>
													<div class="col-md-6">
														<input 
															v-model="companyPostal" 
															:class="{ 'is-invalid' : errors.first('postal') }" 
															name="postal" 
															v-validate="'required'"
															type="text" 
															rows="2"
															class="form-control"
															:disabled="isAddressDisabled">
															<small class="text-danger float-left mt-1">{{ errors.first('postal') }}</small>
													</div>
												</div>
												<div class="row mt-1">
													<div class="col-md-12 clearfix">
														<button @click='updateRecord("address",companyAddress)' :disabled="isAddressDisabled" type="button" class="btn btn-success btn-flat text-white float-right"><i class="fas fa-save"></i></button>
														<button @click="isAddressDisabled ? isAddressDisabled=false : isAddressDisabled=true" type="button" class="btn btn-flat text-white mr-1 float-right" :class="{'btn-info':!isAddressDisabled, 'btn-dark':isAddressDisabled}">
															<i v-if="isAddressDisabled" class="fas fa-lock"></i>
															<i v-else class="fas fa-lock-open"></i>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form-group pt-1">
										<h5>Contact Information</h5>
										<hr />
									</div>
									<div class="form-group row">
										<label class="col-md-2 col-form-label">Office Number</label>
										<div class="col-md-10">
											<div class="input-group">
												<div class="input-group-prepend">
													<span class="input-group-text"><i class="fas fa-phone"></i></span>
												</div>
												<input 
													v-model="companyOfficeNumber" 
													type="text" 
													class="form-control" 
													:class="{ 'is-invalid' : errors.first('office_numb') }" 
													name="office_numb" 
													v-validate="'required'"
													:disabled="isOfficeNumbDisabled">
												<span class="input-group-append">
													<button @click="isOfficeNumbDisabled ? isOfficeNumbDisabled=false : isOfficeNumbDisabled=true" type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isOfficeNumbDisabled, 'btn-dark':isOfficeNumbDisabled}">
														<i v-if="isOfficeNumbDisabled" class="fas fa-lock"></i>
														<i v-else class="fas fa-lock-open"></i>
													</button>
													<button @click='updateRecord("officeNumber",companyOfficeNumber)' :disabled="isOfficeNumbDisabled" type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
												</span>
											</div>
											<small class="text-danger float-left mt-1">{{ errors.first('office_numb') }}</small>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-2 col-form-label">Fax Number</label>
										<div class="col-md-10">
											<div class="input-group">
												<div class="input-group-prepend">
													<span class="input-group-text"><i class="fas fa-fax"></i></span>
												</div>
												<input 
													v-model="companyFaxNumber" 
													:class="{ 'is-invalid' : errors.first('fax_numb') }" 
													name="fax_numb" 
													v-validate="'required'"
													type="text" 
													class="form-control"
													:disabled="isFaxNumbDisabled">
												<span class="input-group-append">
													<button @click="isFaxNumbDisabled ? isFaxNumbDisabled=false : isFaxNumbDisabled=true" type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isFaxNumbDisabled, 'btn-dark':isFaxNumbDisabled}">
														<i v-if="isFaxNumbDisabled" class="fas fa-lock"></i>
														<i v-else class="fas fa-lock-open"></i>
													</button>
													<button @click='updateRecord("faxNumber",companyFaxNumber)' :disabled="isFaxNumbDisabled" type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
												</span>
											</div>
											<small class="text-danger float-left mt-1">{{ errors.first('fax_numb') }}</small>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-2 col-form-label">Email Address</label>
										<div class="col-md-10">
											<div class="input-group">
												<div class="input-group-prepend">
													<span class="input-group-text"><i class="fas fa-envelope"></i></span>
												</div>
												<input 
													v-model="companyEmail" 
													:class="{ 'is-invalid' : errors.first('company_email') }" 
													name="company_email" 
													v-validate="'required|email'"
													type="text" 
													class="form-control"
													:disabled="isEmailDisabled">
												<span class="input-group-append">
													<button @click="isEmailDisabled ? isEmailDisabled=false : isEmailDisabled=true" type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isEmailDisabled, 'btn-dark':isEmailDisabled}">
														<i v-if="isEmailDisabled" class="fas fa-lock"></i>
														<i v-else class="fas fa-lock-open"></i>
													</button>
													<button @click='updateRecord("email",companyEmail)' :disabled="isEmailDisabled" type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
												</span>
											</div>
											<small class="text-danger float-left mt-1">{{ errors.first('company_email') }}</small>
										</div>
									</div>
									<div class="form-group row">
										<label class="col-md-2 col-form-label">Website URL</label>
										<div class="col-md-10">
											<div class="input-group">
												<div class="input-group-prepend">
													<span class="input-group-text"><i class="fas fa-globe-europe"></i></span>
												</div>
												<input 
													v-model="companyWebsite" 
													:class="{ 'is-invalid' : errors.first('website_url') }" 
													name="website_url" 
													v-validate="'required|url: {require_protocol: true}'"
													type="text" 
													class="form-control"
													:disabled="isWebsiteDisabled">
												<span class="input-group-append">
													<button @click="isWebsiteDisabled ? isWebsiteDisabled=false : isWebsiteDisabled=true" type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isWebsiteDisabled, 'btn-dark':isWebsiteDisabled}">
														<i v-if="isWebsiteDisabled" class="fas fa-lock"></i>
														<i v-else class="fas fa-lock-open"></i>
													</button>
													<button @click='updateRecord("website",companyWebsite)' :disabled="isWebsiteDisabled" type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
												</span>
											</div>
											<small class="text-danger float-left mt-1">{{ errors.first('website_url') }}</small>
										</div>
									</div>
								</form>
								<div class="row">
									<div class="col-md-12">
										<hr />
										<employer-admin-users :recordID="employer.id" :AdminUsers="employeUsers"></employer-admin-users>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	import { mapGetters } from 'vuex'
	import UploadPhoto from '../utilities/UploadPhoto'
	import EmployerAdminUsers from '../employers/AdminUsers'

	export default {
		name: 'view-employer',
		components: {
			UploadPhoto,
			EmployerAdminUsers
		},
		data() {
			return {
				employer: [],
				uploadPath: '/images/uploads/',
				isNameDisabled: true,
				isCompSizeDisabled: true,
				isOverviewDisabled: true,
				isAddressDisabled: true,
				isOfficeNumbDisabled: true,
				isFaxNumbDisabled: true,
				isEmailDisabled: true,
				isWebsiteDisabled: true,

				companyName: null,
				companyOverview: null,
				companyOfficeNumber: null,
				companyFaxNumber: null,
				companyEmail: null,
				companyWebsite: null,
				companyAddress: null,
				companyCity: null,
				companyPostal: null,
				industries: [],
				sectorOptions: [],
				is_sectorEmpty: false,
				photo_img: '',
				isShowUploadBtn: false,
				employerJobs: [],
				employeUsers: []

			}
		},
		created() {
			this.preLoadEmployerInformation();
			this.$store.dispatch('fetchSectors');

			if(this.$route.params.id!=this.currentUser.employer_id) {
				this.$router.push('/employer/'+this.currentUser.employer_id);
			} 
		},
		computed: {
			...mapGetters(['sectors']),
			currentUser() {
				return this.$store.getters.currentUser;
			},

			displaySectors() {
				this.sectorOptions = [];
				let currentObject = this;

				this.$store.getters.sectors.forEach( function(item) {
					currentObject.sectorOptions.push(item);
				});

				this.industries.forEach( function(item) {
					currentObject.sectorOptions.includes(item);
					let i = currentObject.sectorOptions.map(item => item.id).indexOf(item.id);
					currentObject.sectorOptions.splice(i, 1);
				});

				return this.sectorOptions;
			}

		},
		methods: {
			updateRecord(columnName,newData) {

				let updatedRecord = []
				let companyCity = this.companyCity
				let companyPostal = this.companyPostal

				if(columnName=="address") {
					updatedRecord = { 
						columnName: columnName,
						'address': newData,
						'city': companyCity,
						'postal': companyPostal,
						'employer_id': this.$route.params.id,
						'user_id' : this.currentUser.id
					}
				} 
				else {
					updatedRecord = { 
						columnName: columnName,
						newdata: newData,
						'employer_id': this.$route.params.id,
						'user_id' : this.currentUser.id
					}
				}

				axios.post('/api/employer/update', updatedRecord, {
					headers: { "Authorization": `Bearer ${this.currentUser.token}`},
				})
				.then((response) => {
					if(response.data.error) {
						this.$swal.fire({
						  icon: 'error',
						  title: 'Oops...',
						  text: response.data.error,
						});
					} 
					else {
						this.$swal.fire({
							position: 'top-end',
							icon: 'success',
							title: 'Record updated successfully',
							showConfirmButton: false,
							timer: 1500
						});
						this.employer = response.data.employer;
					}
				})
				.catch((error) => {
					console.log(error);
				})
			},
			preLoadEmployerInformation() {

				axios.get(`/api/employer/${this.currentUser.employer_id}`, {
					headers: { "Authorization": `Bearer ${this.currentUser.token}`}
				})
				.then((response) => {
					
					let currentObject = this;

					if(response.data.employer) {
						this.employer = response.data.employer;
						this.companyName = this.employer.name;
						this.companyOverview = this.employer.overview;
						this.companyOfficeNumber = this.employer.officeNumber;
						this.companyFaxNumber = this.employer.faxNumber;
						this.companyEmail = this.employer.email;
						this.companyWebsite = this.employer.website;
						this.companyAddress = this.employer.address;
						this.companyCity = this.employer.city;
						this.companyPostal = this.employer.postal;
						this.industries = this.employer.industry;
						this.employerJobs = this.employer.jobs;
						this.employeUsers = this.employer.users;

					} 
					else {
						this.$swal.fire({
							position: 'center',
							icon: 'error',
							title: 'Oops..PAGE NOT FOUND',
							timer: 1500
						});
						setTimeout(() => this.$router.push('/404'), 1500);
					}

				})
				.catch((error) => {
					console.log(error);
				})
			},
			addSector(newSector) {
				this.industries.push(newSector);
				let i = this.sectorOptions.map(item => item.id).indexOf(newSector.id);
				this.sectorOptions.splice(i, 1);
				return false;
			},
			removeSector(selectedSector) {
				this.sectorOptions.push(selectedSector);
				let i = this.industries.map(item => item.id).indexOf(selectedSector.id);
				this.industries.splice(i, 1);
			},
			updateEmployerSector() {
				const getFinalSectors = [];
				let save_industry = '';
				let updatedInfo = [];
				this.is_sectorEmpty = false;

				if(this.industries.length) {
					this.industries.forEach( function(item) {
						getFinalSectors.push({'id':item.id, 'name':item.name})
					});
					save_industry = JSON.stringify(getFinalSectors);
				}  
				else {
					this.is_sectorEmpty = true;
				}

				updatedInfo = {
					'newSector' : save_industry,
					'employer_id' : this.$route.params.id,
					'user_id' : this.currentUser.id
				}

				axios.post('/api/employer/update_sector', updatedInfo, {
					headers: { "Authorization": `Bearer ${this.currentUser.token}`}
				})
				.then((response) => {
					this.industries = response.data.sectors;
					this.$swal.fire({
						position: 'top-end',
						icon: 'success',
						title: 'Record updated successfully',
						showConfirmButton: false,
						timer: 1500
					});
				})
				.catch((error) => {
					console.log(error);
				})
			}
		}
	}
</script>
<style scoped>
</style>