<template>
	<div>
        <h5 class="text-white">{{jobClass}} <i class="fas fa-angle-right"></i></h5>
		<div class="mx-0">
			<carousel :perPageCustom="[[320, 1], [1199, 4]]" 
					:navigationEnabled="true" 
					:autoplay="true" 
					:autoplayTimeout="5000" 
					:paginationEnabled="false" 
					:autoplayHoverPause="true"
					navigationNextLabel="<i class='fa fa-chevron-right text-white'></i>" 
					navigationPrevLabel="<i class='fa fa-chevron-left text-white'></i>">
				<slide class="p-2" v-for="job in jobs" :key="job.id">
					<b-card>
						<template #header >
							<div class="text-center cardHeaderContent">
								<img :src="job.employer.photo_img" class="img-fluid slideCustomImageStyle">
							</div>
				      	</template>
						<b-card-title>
							<strong>{{ job.title }}</strong>
							<p class="my-0">
								<small class="textEmployerName font-italic text-muted">
									By: {{ job.employer.name }}
								</small>
							</p>
							<small class=" textLocationName font-italic text-muted" v-if="job.location"><i class="fas fa-location-arrow"></i> {{ job.location }}</small>
						</b-card-title>
						<b-card-text class="sliderText">
							<ul class="pl-3">
								<li v-if="job.yrs_exp">{{job.yrs_exp}}</li>
								<li v-if="job.career_level">{{job.career_level}}</li>
								<li v-if="job.job_type">{{job.job_type}}</li>
								<li v-if="job.salary">Salary: {{job.salary}}</li>
							</ul>
						</b-card-text>
						<template #footer>
				        	<b-button squared :href="`/apply/${job.slug}`" variant="primary" size="sm" class="float-right py-0">
				        		<small>APPLY</small>
				        	</b-button>
				      	</template>
					</b-card>
				</slide>
			</carousel>
		</div>
	</div>
</template>

<script>
	import { Carousel, Slide } from 'vue-carousel';
	import { mapGetters } from 'vuex';

	export default {
		name: "job-slider",
		props: ['jobClass'],
		components: {
			Carousel,
    		Slide
		},
		computed: {
			currentUser() {
				return this.$store.getters.currentUser;
			}
		},
		data() {
			return {
				slide: 0,
				sliding: null,
				jobs: [],
				per_page_display: 4
			}
		},

		mounted() {
			this.getTheJobs();
		},

		methods: {
			onSlideStart(slide) {
				this.sliding = true
			},

			onSlideEnd(slide) {
				this.sliding = false
			},
			getTheJobs() {
				let jobclass = this.jobClass;
				axios.post('/api/job/getJobs', {jobclass})
					 .then((response) => {
						this.jobs = response.data.jobs
					 })
					 .catch((error) => {
						console.log(error);
					 })
			},

			checkJobTitle(title, limit) {
				if(title.length > limit)
					return true;
				else
					return false;
			}

		}

	}

</script>

<style scoped>
	.sliderTitle {
		font-weight: bolder !important;
		/*text-overflow: ellipsis;
		overflow: hidden;
		display: inline-block;
		white-space: nowrap;
		width: 90% !important;*/
	}
	.sliderText {
		height: 70px !important;
		font-size: 12px !important;
	}
	.textEmployerName {
		font-size: 11px !important;
		display: inline-block;
		/*text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: 90% !important;*/
	}

	.cardHeaderContent {
		height: 85px !important;
	}

	.slideCustomImageStyle {
		mix-blend-mode: multiply !important;
		/*object-fit: cover;*/
		height: 85px !important;
	}

	.textLocationName {
		font-size: 11px !important;
		/*display: inline-block;*/
	}

	.photo_img {
		object-fit: cover;
		width: 13px;
		height: 13px;
	}
</style>