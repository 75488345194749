<template>
    <div>
        <div class="container-fluid">
            <div class="row justify-content-center mt-4">
                <div class="col-md-10">
                    <div class="site_title clearfix text-center" style="padding: 60px;">
                        <img src="/images/logo.png" class="img_logo pr-2" style="width: 75%;">
                    </div>
                </div>
            </div>

            <!-- navigation  -->
            <div class="row justify-content-center nav_bg">
                <div class="col-md-10">
                    <Navigation></Navigation>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-9">
                    <div class="row">
                         <div class="col-md-12 custom-margin">
                        </div>
                        <div class="col-md-12">
                           <div class="row">
                            <!-- <pre>{{newsData[0]}}</pre> -->
                               <div class="col-md-4">
                                   <img :src="newsData[0].a3_poster" class="img-fluid">
                               </div>
                               <div class="col-md-8">
                                    <h3>{{ newsData[0].title }}</h3>

                                    <ul class="pl-3 pt-3 custom_fontsize">
                                        <li class="text-muted">Event Date : {{ dateFormatter(newsData[0].startDate) }}</li>
                                        <li class="text-muted">Start Time : {{ newsData[0].startTime }}</li>
                                        <li class="text-muted">End Time : {{ newsData[0].endTime }}</li>
                                        <li class="text-muted">Venue : {{ newsData[0].venue===null ? newsData[0].venue : 'TBA' }}</li>
                                        <li class="text-muted">Admission : {{ newsData[0].adminFee=='0' ? 'FREE' : newsData[0].adminFee }}</li>
                                    </ul>
                                    <span class="featured-news-content text-muted" v-html="newsData[0].description"></span>
                                    <br />
                                    <a :href="newsData[0].link" target="_blank" type="button" class="btn btn-danger btn-sm">View Details</a>
                               </div>
                           </div>
                        </div>
                        <div class="col-md-12 mt-4">
                           <div class="card rounded-0" v-for="(news, index) in newsData" v-if="index > 0">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <img :src="news.thumb" class="img-fluid">
                                        </div>
                                        <div class="col-md-9">
                                            <h4 class="mb-0">{{ news.title }}</h4>
                                            <ul class="pl-3 pt-3 custom_fontsize">
                                                <li class="text-muted">Event Date : {{ dateFormatter(news.startDate) }}</li>
                                                <li class="text-muted">Start Time : {{ news.startTime }}</li>
                                                <li class="text-muted">End Time : {{ news.endTime }}</li>
                                                <li class="text-muted">Venue : {{ news.venue===null ? news.venue : 'TBA' }}</li>
                                                <li class="text-muted">Admission : {{ news.adminFee=='0' ? 'FREE' : news.adminFee }}</li>
                                            </ul>
                                            <span v-html="news.description"></span>
                                            <br />
                                            <br />
                                            <a :href="news.link" target="_blank" type="button" class="btn btn-danger btn-sm">View Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- footer -->
            <pageFooter></pageFooter>
        </div>
    </div>
</template>
<script>
    import Navigation from '../../layout/Navigation';
    import pageFooter from '../../layout/Footer';

    import moment from 'moment';

    export default {
        name: "show-job",
        components: {
            Navigation,
            pageFooter
        },
        data() {
            return {
                isNews: true,
                newsData: [],
            }
        },
        created() {
          this.fetchNewsEvents();
        },
        methods: {
            dateFormatter(data) {
                return moment(data).format("MMM DD, YYYY");
            },
            dateCounter(data) {
                return moment(data,"YYYYMMDD").fromNow();
            },
            async fetchNewsEvents() {

                const apiURL = '/api/getNewsEvents';
                try {
                    const response = await axios.get(apiURL);
                    this.newsData = response.data.events
                    console.log(response.data.events);
                } 
                catch(error) {
                    throw error;
                }

            },
        }

    }

</script>
<style scoped>
    .card:hover {
        background: #f9f9f9;
    }

    .custom-margin {
        padding: 25px 15px 40px 15px;
    }

    .custom-nav-link {
        background-color: #fff !important;
        border-color: #dee2e6 !important;
    }

    .custom-nav-link:hover {
        text-decoration: none;
        background: #ccc !important;
    }

    .active_tab {
        background-color: #d2232a !important;
        color: #fff;
    }

    .custom_img {
        width: 60px !important;
        float: left;
        margin-right: 10px;
    }

    .featured-news-content {
        font-size: 15px;
        line-height: 25px;
    }

    .custom_fontsize li{
        font-size: 12px;
    }
</style>