<template>
	<div>
		<div id="main" class="wrapper">
			<top-navigation />
			<sidebar />
			<div class="content-wrapper pt-4">
				<div class="content">
					<div class="container-fluid">
						<div class="row">
							<div class="col-md-8">
								<div class="row">
									<div class="col-md-4">
										<div class="info-box">
											<span class="info-box-icon bg-yellow"><i class="fas fa-user-friends text-white"></i></span>
											<div class="info-box-content">
												<span class="info-box-text">Total Job Posted</span>
												<span class="info-box-number custom_size">
													<template v-if="totalJob==''"><Stretch></Stretch></template>
													<template v-else>{{totalJob}}</template>
												</span>
											</div>
										</div>
									</div>
									<div class="col-md-4">
										<div class="info-box">
											<span class="info-box-icon bg-yellow"><i class="fas fa-file-invoice text-white"></i></span>
											<div class="info-box-content">
												<span class="info-box-text">Total Applicants</span>
												<span class="info-box-number custom_size">
													<template v-if="totalApplication==''"><Stretch></Stretch></template>
													<template v-else>{{totalApplication}}</template>
												</span>
											</div>
										</div>
									</div>
									<div class="col-md-4">
										<div class="info-box">
											<span class="info-box-icon bg-yellow"><i class="fas fa-thumbs-up text-white"></i></span>
											<div class="info-box-content">
												<span class="info-box-text">Total Hired Applicants</span>
												<span class="info-box-number custom_size">
													<!-- <template v-if="totalHired==''"><Stretch></Stretch></template> -->
													<template>{{ totalHired }}</template>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
											<div class="card card-success card-outline">
												<div class="card-header py-3">
													<h5 class="m-0"><strong>Pending Applications</strong></h5>
												</div>
												<div class="card-body table-responsive p-0" style="height: 425px;">
													<v-table :data="applications" class="table table-head-fixed table-bordered table-hover" 
																:currentPage.sync="currentPage" :pageSize="10" @totalPagesChanged="totalPages = $event" :filters="filters">
														<thead slot="head">
															<tr class="tbl_header">
																<th><small>Job Position</small></th>
																<th><small>Type</small></th>
																<th><small>Applicant</small></th>
																<th><small>Phone #</small></th>
																<th><small>Email</small></th>
																<th colspan="2"><small>Date Submitted</small></th>
															</tr>
														</thead>
														<tbody slot="body" slot-scope="{displayData}">
															<template v-if="!applications.length">
																<tr v-if="applications.length==0">
																	<td colspan="7" class="text-center py-4">
																		<p class="text-muted font-italic">No pending applications found</p>
																	</td>
																</tr>
																<tr v-else>
																	<td colspan="7" class="text-center py-4">
																		<center><Stretch></Stretch></center>
																	</td>
																</tr>
															</template>
															<template v-else>
																<tr v-for="application in displayData" :key="application.id">
																	<td class="align-middle">
																		<img :src="application.job_img" class="img-circle photo_img" />
																		<small>{{ application.title }}</small>
																	</td>
																	<td class="align-middle"><small>{{ application.job_type }}</small></td>
																	<td class="align-middle">
																		<img :src="application.profile_img" class="img-circle photo_img" />
																		<small>{{ application.firstname }} {{ application.lastname }}</small>
																	</td>
																	<td class="align-middle"><small>{{ application.phone }}</small></td>
																	<td class="align-middle"><small>{{ application.email }}</small></td>
																	<td class="align-middle">
																		<small>
																			{{ dateFormatter(application.created_at) }} 
																			<i>({{ dateCounter(application.created_at) }})</i>
																		</small>
																	</td>
																	<td class="text-right align-middle p-0">
																		<router-link :to="`/application/${application.id}`" class="text-primary mr-3">
																			<small><i class="fas fa-arrow-right"></i></small>
																		</router-link>
																	</td>
																</tr>
																<tr v-if="displayData.length==0">
																	<td colspan="7" class="text-center">No applications found</td>
																</tr>
															</template>
														</tbody>
													</v-table>
												</div>
												<div class="card-footer py-3">
													<router-link to="/employers" class="btn btn-primary btn-sm float-right"><i class="fas fa-eye"></i>
														&nbsp;<small>VIEW ALL</small>
													</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
							<div class="col-md-4">
								<ActivityLogs></ActivityLogs>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import {Stretch} from 'vue-loading-spinner'
	import moment from 'moment'
	import Multiselect from 'vue-multiselect'
	import { mapGetters } from 'vuex'
	import ActivityLogs from '../utilities/Logs';
	import SearchRecords from '../utilities/SearchRecord';

	import TopNavigation from '../../layout/TopNavigation.vue'
	import Sidebar from '../../layout/Sidebar.vue'

	export default {
		name: 'dashboard',
		components: {
			Stretch,
			ActivityLogs,
			Multiselect,
			SearchRecords,
			TopNavigation,
			Sidebar
		},
		data() {
			return {
				currentPage: 1,
				totalPages: 0,
				filters: {
					name: { value: '', keys: ['jobs.employer', 'title', 'type', 'industry'] }
				},
				uploadPath: '/images/uploads/',
				dummyNumber: ["5", "10", "7", "21", "1", "14"],
				randomClassLabel: ['bg-primary', 'bg-warning', 'bg-success', 'bg-info'],

				totalJob: 0,
				totalApplication: 0,
				totalHired: 0
				
			}
		},
		created() {
			this.fetchApplications();
			this.getTotalJobs();
		},
		computed: {
			...mapGetters(['employers', 'jobs', 'applications']),
			currentUser() {
				return this.$store.getters.currentUser;
			}
		},
		methods: {
			randomClass() {
				return this.randomClassLabel[Math.floor(Math.random()*this.randomClassLabel.length)]
			},

			dateFormatter(data) {
				return moment(data).format("MMM DD, YYYY");
			},
			dateCounter(data) {
				return moment(data,"YYYYMMDD").fromNow();
			},

			async fetchApplications() {
				await this.$store.dispatch('fetchApplications', this.currentUser.employer_id);
			},

			async getTotalJobs() {

				let userData = {
					'employer_id': this.currentUser.employer_id
				};

				await axios.get(`/api/dashboard/summary/${this.currentUser.employer_id}`, {
					headers: { "Authorization": `Bearer ${this.currentUser.token}`}
				})
				.then((response) => {
					this.totalJob = response.data.summary.getTotalJob;
					this.totalApplication = response.data.summary.getTotalApplication;
					this.totalHired = response.data.summary.getTotalHired;
				})
				.catch((error) => {
					console.log(error);
				})
			}

		}
	}

</script>
<style scoped>
	.photo_img {
		object-fit: cover;
		width: 20px;
		height: 20px;
	}
	.custom_link:hover {
		color: #212529 !important;
		background-color: #e9ecef !important;
	}

	.tbl_header th {
		background: #444 !important;
		font-weight: bold !important;
		color: #fff !important;
	}
	.custom_size {
		font-size: 30px !important;
	}
</style>