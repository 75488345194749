<template>
	<div>
		<div class="content-wrapper pt-4">
			<div class="content">
				<div class="container-fluid">
					<div class="row">
						<div class="col-md-12">
							<div class="error-page">
								<h2 class="headline text-warning"> 404</h2>
								<div class="error-content">
									<h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found.</h3>
									<p>
										We could not find the page you were looking for.<br />
										Meanwhile, you may <a :href="this.$router.push('/')">return to dashboard</a> 
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	import CircleSpin from 'vue-loading-spinner/src/components/Circle'
	
	export default {
		name: 'page-note-found',
		components: {
			CircleSpin
		},
		created() {
			// setTimeout(() => this.$router.push('/'), 3000);
		}
	}

</script>