<template>
    <div>
    	<div class="container-fluid">
    		<div class="row justify-content-center mt-4">
                <div class="col-md-10">
                    <div class="site_title clearfix text-center" style="padding: 60px;">
                        <img src="/images/logo.png" class="img_logo pr-2" style="width: 75%;">
                    </div>
                </div>
            </div>

            <!-- navigation  -->
            <div class="row justify-content-center nav_bg">
                <div class="col-md-10">
                    <Navigation></Navigation>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-12 title-margin">
                            <h4>Organisation Profiles</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 text-center">
                            <img src="/images/partners/agwo.png" class="img-fluid" width="75%">
                        </div>
                        <div class="col-md-9">
                            <p>
                                The Alliance of Guest Workers Outreach (AGWO) partners with various Voluntary Welfare Organisations, civil organisations, corporations, professional bodies, guest worker dormitories, and government agencies. It is the aim of the AGWO to provide guest workers in Singapore with holistic care in a sustainable manner. The AGWO also hopes to establish a platform for collaborations, partnerships, and the sharing of resources and services, which will benefit guest workers residing in Singapore.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <hr />
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="/images/partners/brc_logo.jpeg" class="img-fluid" width="75%">
                        </div>
                        <div class="col-md-9">
                            <p>
                                The Bible Resource Centre is a hub of operations for the distribution of Scriptures/ Scripture-related materials to all segments of the Christian community in Singapore and regionally. BRC also manages a supply chain for Scripture resource distribution.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <hr />
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="/images/partners/eas.png" class="img-fluid" width="75%">
                        </div>
                        <div class="col-md-9">
                            <p>
                                Evangelical Alliance Of Singapore (EAS) exists to promote and nurture networks and collaborations among Evangelicals in Asia for the purpose of strengthening and expanding the Kingdom of God in Asia and beyond. EAS also seeks to stimulate, encourage, and support Asian churches’ engagement in making disciples (holistically) of all people (nations).
                            </p>
                            <p>
                                EAS also seeks to promote and nurture unity, collaborations and cooperation among ministry organizations, church networks, the National Evangelical Alliances within Asia, and with those outside Asia for the purpose of building, strengthening, and expanding the Kingdom of God in Asia and beyond.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <hr />
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="/images/partners/ethos.png" class="img-fluid" width="60%">
                        </div>
                        <div class="col-md-9">
                            <p>
                                The ETHOS Institute™ for Public Christianity is a Christian think-tank formed to serve the church and society by engaging contemporary issues and trends from the Christian perspective. The organisation also offers resources to churches and Christians in different professions and vocations.
                            </p>
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <hr />
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="/images/partners/hia.png" class="img-fluid" width="68%">
                        </div>
                        <div class="col-md-9">
                            <p>
                                Hope Initiative Alliance is an inter-racial and inter-religious alliance of social service organisations, civil groups and corporations that seek to provide holistic care for the vulnerable and marginalised communities.
                            </p>
                            <p>
                                As a comprehensive aggregator of assistance and services for the needy, we partner with more than 100 organisations, including government agencies, social service organisations, civil groups, corporations and volunteers to help the guest workers, homeless, elderly, people with disabilities, the poor and needy communities.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <hr />
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="/images/partners/ibex.png" class="img-fluid" width="68%">
                        </div>
                        <div class="col-md-9">
                            <p>
                                IBEx is a travel agency in Singapore that specialises in Biblical tours and Bible mission trips. We cover the Bible lands and countries related to Church history and the Christian faith. We also strive to maintain quality services by engaging experienced spiritual directors, good reliable airlines, and land operators to create memorable experiences for everyone.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <hr />
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="/images/partners/sowcare.png" width="35%" class="img-fluid">
                        </div>
                        <div class="col-md-9">
                            <p>
                                SowCare aims to serve, empower and sow holistic care for the underprivileged communities in Singapore and beyond. Through partnership with various voluntary welfare organisations, we seek to reach out to the guest workers, homeless, poor, and needy communities.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <hr />
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="/images/partners/sowclean.png" width="35%" class="img-fluid">
                        </div>
                        <div class="col-md-9">
                            <p>
                                SowClean is your one-stop affordable cleaning solution that provides quality housekeeping and cleaning solutions for both residential and commercial properties. We believe in working closely with our clients to provide tailored solutions that meet your needs, providing you with peace of mind to focus on what matters.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <hr />
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="/images/partners/sower_essentials.png" class="img-fluid" width="70%">
                        </div>
                        <div class="col-md-9">
                            <p>
                                Sower Essentials Pte Ltd is a Christian social enterprise founded in 2015. The enterprise strives to meet your needs by providing a wide range of resources, services, and goods that reflect the essence of Christ.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <hr />
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="/images/partners/sibd.png" class="img-fluid" width="75%">
                        </div>
                        <div class="col-md-9">
                            <p>
                                Sower Institute for Biblical Discipleship (SIBD) offers Bible engagement programmes, events, and projects which aim to further advocacy and engagement aims in the public and Christian spheres. SIBD also organises Christian education workshops and talks, coordinates regional literacy initiatives, and works with various stakeholders to plan and deliver training programmes.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <hr />
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="/images/partners/sol_logo.png" class="img-fluid" width="75%">
                        </div>
                        <div class="col-md-9">
                            <p>
                                Streams Of Life provides communication and media services to the Christian community. By making use of both conventional and new media, SOL perseveres in being a portal that effectively communicates hope and inspiration to the integration of faith and life.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <hr />
                        </div>
                        <div class="col-md-3 text-center">
                            <img src="/images/partners/bss.png" width="50%" class="img-fluid">
                        </div>
                        <div class="col-md-9">
                            <p>
                                The Bible Society of Singapore strives to make the Word of God known to all people by making it available through the work of translation & publishing, accessible through distribution & literacy programmes, and credible through engagement & advocacy.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- footer -->
            <Footer></Footer>
        </div>
    </div>
</template>
<script>
    import Navigation from '../../layout/Navigation';
    import Footer from '../../layout/Footer';

    export default {
        name: "organization-profile",
        components: {
            Navigation,
            Footer
        }
    }

</script>
<style scoped>
    .custom-margin {
        padding: 15px 0 15px 15px;
    }

    .title-margin {
        padding: 25px 0 40px 15px;
    }

</style>

