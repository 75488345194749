<template>
    <div>
        <div class="container-fluid">
            <div class="row justify-content-center mt-4">
                <div class="col-md-10">
                    <div class="site_title clearfix text-center" style="padding: 60px;">
                        <img src="/images/logo.png" class="img_logo pr-2" style="width: 75%;">
                    </div>
                </div>
            </div>

            <!-- navigation  -->
            <div class="row justify-content-center nav_bg">
                <div class="col-md-10">
                    <Navigation></Navigation>
                </div>
            </div>
            <div class="row justify-content-center" style="min-height: 425px !important;">
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <h4>{{ newsTitle }}</h4>
                            <br />
                            <p>
                                This Privacy Policy discloses how the Christian Ministry Traineeship Programme (CMTP) and its partnering organisations collect, store and handle the Personal Data they gather, in accordance with the Personal Data Protection Act 2012. We appreciate the trust you have in us and are committed to safeguarding your personal information at all times. By providing your Personal Data to us, you are consenting to this Privacy Policy and the collection, use, access, transfer, storage and processing of your personal information as described in this Policy.
                            </p>
                            <ol>
                                <li class="mb-4">
                                    <h4>Our Commitment to Protect Your Privacy</h4>
                                    <p>The Christian Ministry Traineeship Programme is committed to protecting your personal and data privacy. To ensure that you can make informed decisions and feel confident about supplying your Personal Data to us when using our website and other services, we provide this notice outlining our practices and the choices you have concerning how your Personal Data is being collected and used by us.</p>
                                </li>
                                <li class="mb-4">
                                    <h4>The Personal Data We Collect</h4>
                                    <p>“Personal Data” refers to data, whether true or not, about an individual who can be identified from that data; or from that data and other information to which the organisation has or is likely to have access.</p>

                                    <p>This includes unique identifiers (e.g. NRIC number, passport number); as well as any set of data (e.g. name, age, address, telephone number, occupation, photographs, video/audio recordings etc), which when taken together would be able to identify the individual.</p>
                                </li>
                                <li class="mb-4">
                                    <h4>Purposes for which the Personal Data are Collected and Used</h4>
                                    <p>We may use the Personal Data you provided for one or more of the following purposes:</p>
                                    <ul>
                                        <li>To contact you regarding your enquiries;</li>
                                        <li>To process and administer any services requested by you;</li>
                                        <li>For identification and verification purposes in connection with any of the services that may be supplied to you;</li>
                                        <li>For your use of the online services available at website;</li>
                                        <li>
                                            For marketing, promotional, and relationship management purposes such as sending you updates on Bible Mission job positions, traineeship opportunities, and events in connection with our services;
                                        </li>
                                        <li>
                                            To disclose to a third party to comply with any law, legal requirements, orders, directions or requests from any court, authority or government body of any jurisdiction, which may be within or outside of Singapore;
                                        </li>
                                        <li>
                                            For internal and external advertisement purposes in mediums such as printed newsletters, brochures, flyers, posters, banners, calendars, electronic publications, websites, and social media platforms (including, but not limited to, photographs and audio/video recordings during events organised by the admins of the CMTP website or other Christian ministries and organisations). We may from time to time use aggregate non-identifying information about our customers to better design our website and/or to improve our services. This means we may provide this information to third parties. However, this information will never identify any single user in particular. The admins managing the CMTP website reserve the right to access all CVs / resumes submitted through the website. Except as provided above as well as below, we will not knowingly or intentionally use or share the Personal Data you provide to use in ways unrelated to the aforementioned purposes without your prior consent.
                                        </li>
                                    </ul>
                                </li>
                                <li class="mb-4">
                                    <h4>How to Access or Correct Your Personal Data</h4>
                                    <p>You may request access to your Personal Data held by us if you wish to obtain a copy of any of your Personal Data. If you believe that the Personal Data relating to you which we collect and maintain is incorrect, used beyond the scope of the purpose of use disclosed above, was acquired by fraudulent or unlawful means, or provided to a third party without your prior consent, please write to us at the address below.</p>

                                    <p>A request for access, correction to, or deletion of either Personal Data or information regarding policies and practices and kinds of Personal Data held by us must be emailed to the following address: traineeship@streamsoflife.sg</p>
                                </li>
                                <li class="mb-4">
                                    <h4>Use of Cookies on our website</h4>
                                    <p>We may use temporary “cookies” in our website that collect the first level domain name of your email address. For instance, if your email address is name@domain.com, the cookie collects the “domain.com” portion of your email address and the date and time you accessed this website. Cookies by themselves cannot be used to discover your identity.</p>

                                    <p>A cookie is a small piece of information that is sent to your browser and stored on your computer’s hard drive. You can set your browser to notify you when you receive a cookie. This enables you to decide if you want to accept the cookie or not.</p>
                                </li>
                                <li class="mb-4">
                                    <h4>Links to Other Sites</h4>
                                    <p>Our website contains hyperlinks to other websites that are not operated by the Christian Ministry Traineeship Programme but are given for your convenience and reference only. The provision of these hyperlinks does not imply that we endorse the contents or any of the programs and activities found on these websites. We do not control these websites and are not responsible for their personal data and privacy practices.</p>
                                </li>
                                <li class="mb-4">
                                    <h4>Our Commitment to Personal Data Security</h4>
                                    <p>To maintain the accuracy of the Personal Data, as well as to prevent unauthorised access and ensure the correct use of Personal Data, we have implemented appropriate physical, technical, and administrative measures to safeguard and secure the Personal Data we collect.</p>

                                    <p>All electronic Personal Data that we maintain is securely stored and further protected through our use of appropriate and authentic access controls. When disposing of Personal Data, paper documents containing Personal Data are securely destroyed, and electronic files storing Personal Data are permanently deleted.</p>
                                    <p>In some instances, we may entrust Personal Data to third party service providers, binding them to protect the security of Personal Data and only to use it for the purposes we specify.</p>
                                </li>
                                <li class="mb-4">
                                    <h4>Disclosure and Transfer of Personal Data</h4>
                                    <p>We do not sell, rent, lend or exchange your Personal Data to/with any third party. However, we may disclose and transfer Personal Data to and jointly use Personal Data with our business associates, service providers, and other persons who we consider appropriate, in connection with the services provided to or requested by you.</p>
                                    <p>The entities with whom we may share your Personal Data include but are not limited to:</p>
                                    <ul>
                                        <li>any agent, contractor or third party service provider who provides administrative, marketing and research, distribution, data processing, telecommunications, computer, payment, or other services to the Christian Ministry Traineeship Programme;</li>
                                        <li>credit, debit and /or charge card companies and/or banks;</li>
                                        <li>government or non-government authorities, agencies and/or regulators;</li>
                                        <li>medical professionals, insurers, and clinics/hospitals.</li>
                                    </ul>
                                    <p>We will require the entities above to ensure that the Personal Data disclosed to them is kept confidential and secure.</p>
                                </li>
                                <li class="mb-4">
                                    <h4>Changes to Privacy Policy</h4>
                                    <p>We will post on our website any changes to this policy with the effective date of the changed policy, so that you can be informed of the way we collect and use your Personal Data any time you so choose. If at any point we decide to use the Personal Data you submitted under this current policy in a way that differs materially from the privacy policy that applied at the time of that submission, you will be notified and given the opportunity via the website, email, or in writing to opt out or otherwise prevent such usage.</p>
                                </li>
                                <li class="mb-4">
                                    <h4>Withdrawal from Direct Marketing</h4>
                                    <p>If you do not wish to receive our newsletters (electronic or hard copies), information about our programmes, or promotions about our services, you may opt out at any time by:</p>
                                    <ul>
                                        <li>Following the unsubscribe instructions contained in the communications</li>
                                        <li>Emailing to us at traineeship@streamsoflife.sg</li>
                                    </ul>
                                </li>
                                <li class="mb-4">
                                    <h4>Retention of Data</h4>
                                    <p>All Personal Data that has been collected from you will only be stored for as long as it is relevant to the purpose for which it was processed and for as long as required by applicable law.</p>
                                </li>
                                <li class="mb-4">
                                    <h4>Data Protection Officer</h4>
                                    <p>For any queries or issues regarding the Christian Ministry Traineeship Programme’s privacy policy, please contact the Data Protection Officer at traineeship@streamsoflife.sg.</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- footer -->
            <pageFooter></pageFooter>
        </div>
    </div>
</template>
<script>
    import Navigation from '../../layout/Navigation';
    import pageFooter from '../../layout/Footer';

    import moment from 'moment';

    export default {
        name: "privacy-policy",
        components: {
            Navigation,
            pageFooter
        },
        data() {
            return {
                newsTitle: null,
            }
        },
        created() {
          this.newsTitle = "Privacy Policy";
        },
        methods: {
            dateFormatter(data) {
                return moment(data).format("MMM DD, YYYY");
            },
            dateCounter(data) {
                return moment(data,"YYYYMMDD").fromNow();
            }
        }

    }

</script>
<style scoped>
    .custom-margin {
        padding: 25px 15px 15px 15px;
    }
</style>