<template>
	<div class="content-wrapper pt-4">
		<div class="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-12">
						<router-view></router-view>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'job-main',
	}

</script>