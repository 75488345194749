import { render, staticRenderFns } from "./contactUs.vue?vue&type=template&id=29551247&scoped=true&"
import script from "./contactUs.vue?vue&type=script&lang=js&"
export * from "./contactUs.vue?vue&type=script&lang=js&"
import style0 from "./contactUs.vue?vue&type=style&index=0&id=29551247&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29551247",
  null
  
)

export default component.exports