<template>
	<div>
		<div class="container-fluid">

			<div class="row justify-content-center mt-4">
                <div class="col-md-10">
                    <div class="site_title clearfix text-center" style="padding: 60px;">
                        <img src="/images/logo.png" class="img_logo pr-2" style="width: 75%;">
                    </div>
                </div>
            </div>

            <!-- navigation  -->
            <div class="row justify-content-center nav_bg">
                <div class="col-md-10">
                    <Navigation></Navigation>
                </div>
            </div>

            <div class="row justify-content-center photo_header">
                <div class="col-md-10">
                    <div class="row feature_wrapper">
                        <div class="col-md-12">
                            <h3 class="text-white font-weight-bold">PUT YOUR GIFTS TO GOOD USE AND SERVE IN A CHRISTIAN MINISTRY</h3>
                            <p class="text-white mt-4">
                                Opening traineeship opportunities and more for 
                                those looking for ministry experiences in Christian 
                                organisations.
                            </p>
                        </div>
                    </div>
                    <form @submit.prevent="searchNow()">
                        <div class="row mt-4">
                            <div class="col-md-6 mb-2">
                                <i class="fas fa-search search_icon"></i>
                                <input type="text" class="form-control form-control-lg search_input1" v-model="keyword" placeholder="JOB TITLE OR ANY KEYWORD">
                            </div>
                            <div class="col-md-5 mb-2">
                                <img src="/images/focus.png" class="focus_icon" width="30">
                                <multiselect v-model="jobClassQuery" :searchable="false" 
                                        :close-on-select="true" :options="fetchJobClass" 
                                        :multiple="true" group-values="sub_classes" 
                                        group-label="name" :group-select="true" 
                                        placeholder="SELECT JOB CLASSIFICATIONS" 
                                        track-by="name" label="name">
                                </multiselect>
                            </div>
                            <div class="col-md-1 mb-4">
                                <button type="submit" class="btn search_btn btn-lg text-white">SEARCH</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

            <div class="row justify-content-center pt-4 pb-4">
                <div class="col-md-8 mt-4">
                    <div v-if="isSearching" class="row">
                        <div class="col-md-12">
                            <template v-if="isLoading">
                                <center><Stretch></Stretch></center>
                            </template>
                            <template v-if="showNoResult">
                                <center><h4 class="text-muted">No result found.</h4></center>
                            </template>
                            <template v-if="isLoaded">
                                <div class="clearfix">
                                    <div v-for="job in searchResult" class="w-50 float-left">
                                        <div class="card mx-2">
                                            <div class="card-body py-4">
                                                <div class="row">
                                                    <div class="col-md-4 text-center">
                                                        <img :src="job.employer.photo_img==null ? '/images/default_user.png' : job.employer.photo_img" class="img-fluid">
                                                    </div>
                                                    <div class="col-md-8">
                                                        <h5>{{ job.title }}</h5>
                                                        <p class="text-muted mb-0">{{ job.employer.name }}</p>
                                                        <p class="text-muted"><small>Posted {{ dateFormatter(job.created_at) }} <i>({{ dateCounter(job.created_at) }})</i></small></p>
                                                        <ul class="pl-3">
                                                            <li><small>{{ job.job_type }}</small></li>
                                                            <li><small>{{ job.career_level }}</small></li>
                                                            <li><small>{{ job.yrs_exp }}</small></li>
                                                        </ul>
                                                        <router-link :to="{ path: '/get-job/'+job.slug }" class="btn btn-danger btn-sm py-0">
                                                            <small>VIEW <i class="fas fa-arrow-right"></i></small>
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-md-4">
                            <img src="/images/1.jpg" class="img-fluid mb-4">
                            <img src="/images/2.jpg" class="img-fluid mb-4">
                            <img src="/images/3.jpg" class="img-fluid mb-4">
                        </div>
                        <div class="col-md-8 content_title">
                            <h4 class="mb-4">Launch of Christian Ministry Traineeship Programme—Equipping graduates in the Christian community with professional skills and ministry experiences</h4>

                            <p>The Bible Society of Singapore (BSS) has launched a new initiative, the Christian Ministry Traineeship Programme, which aims to support fresh graduates in taking up traineeship opportunities across various sectors.</p>

                            <p>The Christian Ministry Traineeship Programme allows graduates in the Christian community and people who are looking for ministry experiences to apply for traineeship positions in Christian organisations to pick up industry-related experience and exposure, allowing them to develop their skills professionally and boosting their employability in preparation for future opportunities.</p>

                            <p>Various partnering organisations and ministries have joined BSS in opening up traineeship positions, where job seekers can apply for roles they are interested in. Some of the organisations (charities, social enterprises and Christian organisations) include Evangelical Alliance of Singapore (EAS) and Hope Initiative Alliance (HIA). Organisations are welcome to come onboard as host organisations to offer attachments and traineeship positions to individuals under this programme.</p>

                            <p>Each traineeship programme lasts between 6 to 12 months, where applicants work alongside professionals in order to be equipped with the necessary skills. This will enable them to gain a firmer foothold in the job market even during the current economic climate. There is also a sub-category where applicants can apply for full-time job positions.</p>

                            <p>Eligible new graduates can search for traineeship opportunities, where job openings will be updated by host organisations. A community news portal for the wider Christian community, Streams of Life is a digital meeting place for all—be it church, charity, organisation, or individual—to bring hope and inspiration to the integration of faith and life.</p>

                            <p>If your organisation has traineeship opportunities, or if you have partners, charity, or commercial entities with job openings that you would like to include on the platform, please contact us at <strong>traineeship@streamsoflife.com.sg</strong>.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center py-4" style="background-color: #d2232a !important;">
                <div class="col-md-8 m-4 qv_title_wrapper">
                    <span class="qv_title text-white">Quick View</span>
                    <a href="/traineeship-programmes" class="text-white see_all" >SEE ALL <i class="fas fa-angle-right"></i></a>
                </div>
                <div v-for="jobClass in jobClassInitSet" class="col-md-8 my-4">
                	<Slider :jobClass="jobClass"></Slider>
                </div>
            </div>

            <!-- ministry sponsors -->
            <div class="row justify-content-center pt-4 pb-4" style="background-color: #fff !important; padding-bottom: 75px !important;">
                <div class="col-md-9" style="margin-top:2% !important;">
                    <h5 class="text-dark mb-4">Partnering Organisations and Ministries (in Alphabetical Order)</h5>
                </div>
                <div class="col-md-9">
                    <div class="row pb-4">
                        <div class="col-md-2 text-center pt-4">
                            <img src="/images/partners/agwo.png" width="70%">
                        </div>
                        <div class="col-md-2 text-center pt-4">
                            <img src="/images/partners/brc_logo.jpeg" width="75%">
                        </div>
                        <div class="col-md-2 text-left pt-4">
                            <img src="/images/partners/eas.png" width="90%">
                        </div>
                        <div class="col-md-2 text-center pt-2">
                            <img src="/images/partners/ethos.png" width="55%">
                        </div>
                        <div class="col-md-2 text-center">
                            <img src="/images/partners/hia.png" width="80%">
                        </div>
                        <div class="col-md-2 text-center pt-4">
                            <img src="/images/partners/ibex.png" width="60%">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2 text-center">
                            <img src="/images/partners/sowcare.png" width="45%">
                        </div>
                        <div class="col-md-2 text-center">
                            <img src="/images/partners/sowclean.png" width="40%">
                        </div>
                        <div class="col-md-2 text-center pt-4">
                            <img src="/images/partners/sower_essentials.png" width="80%">
                        </div>
                        <div class="col-md-2 text-center pt-4">
                            <img src="/images/partners/sibd.png" width="90%">
                        </div>
                        <div class="col-md-2 text-center">
                            <img src="/images/partners/sol_logo.png" width="100%">
                        </div>
                        <div class="col-md-2 text-center">
                            <img src="/images/partners/bss.png" width="50%">
                        </div>
                    </div>
                </div>
            </div>

            <!-- footer -->
            <Footer></Footer>
		</div>
	</div>
</template>

<script>
	
	import Slider from './utilities/JobSlider';
	import Navigation from '../layout/Navigation';
	import Footer from '../layout/Footer';
    import {Stretch} from 'vue-loading-spinner'

    import Multiselect from 'vue-multiselect';
    import moment from 'moment';

	export default {
		name: "Home",
		components: {
			Slider,
			Navigation,
			Footer,
            Multiselect,
            Stretch
		},
		data() {
			return {
				// jobClassInitSet: ['Communications/Media', 'Sales/Retail', 'Community Engagement'],
                jobClassInitSet: [],
                fetchJobClass: [],
                jobClassQuery: [],
                keyword: null,
                searchResult: [],
                isSearching: false,
                isLoaded: false,
                isLoading: false,
                showNoResult: false
			}
		},
        created() {
            this.fetchJobClassification();
            this.jobClassInitSet = this.jobClassInitSet.length==0 ? ['Accounting/Finance', 'Computer/InformationTechnology', 'Admin/Human Resource', 'Arts/Media/Communications'] : this.jobClassInitSet;
        },
        methods: {
            async fetchJobClassification(){
                try {
                    const response = await axios.get('/api/classifications');
                    this.fetchJobClass = response.data.jobclass;
                } 
                catch(error) {
                    throw error;
                }
            },
            async searchNow() {
                
                this.isSearching = true;
                this.searchResult = [];
                this.isLoading = true;
                this.showNoResult = false;

                let selectedSubClass = [];
                this.jobClassQuery.forEach((obj) => {
                    selectedSubClass.push(obj.name)
                })
                let query = {
                    'keyword': this.keyword,
                    'jobClass': JSON.stringify(selectedSubClass)
                }
                try {
                    const response = await axios.post('/api/searchJob', {data:query});

                    if(response.data.jobs.length > 0) {
                        this.isLoaded = true;
                        this.isLoading = false;
                        this.showNoResult = false;
                        this.searchResult = response.data.jobs;
                    } else {
                        this.isLoading = false;
                        this.showNoResult = true;
                    }

                } 
                catch(error) {
                    throw error;
                }
            },
            dateFormatter(data) {
                return moment(data).format("MMM DD, YYYY");
            },
            dateCounter(data) {
                return moment(data,"YYYYMMDD").fromNow();
            }
        },
	}

</script>
<style scoped>
    .setMinHeight {
        min-height: 400px !important;
    }

    .dropdown-menu {
        min-width: 8rem !important;
    }

    .dropdown-menu li {
        padding: 5px !important;
        font-weight: bold !important;
    }

    .search_btn {
        font-size: 15px !important;
        background-color: #d2232a !important;
        padding-block: 18px !important;
        border-radius: 2px !important;
    }

    .search_input1::placeholder, .search_input1 .filter-option-inner-inner, .search_input2::placeholder {
        color: #495057 !important;
        opacity: 0.4 !important; /* Firefox */
    }

    .search_input1 {
        font-size: 17px !important;
        border-radius: 2px !important;
        padding-left: 45px !important;
        color: #827f7f !important;
        padding-top: 30px !important;
        padding-bottom: 27px !important;
    }

    .search_input1:-ms-input-placeholder, .search_input1 .filter-option-inner-inner, .search_input2:-ms-input-placeholder, .search_input2 .filter-option-inner-inner { /* Internet Explorer 10-11 */
        color: #495057 important;
    }

    .search_input1::-ms-input-placeholder, .search_input1 .filter-option-inner-inner, .search_input2::-ms-input-placeholder, .search_input2 .filter-option-inner-inner { /* Microsoft Edge */
        color: #495057 important;
    }

    .search_icon {
        position: absolute;
        top: 17px;
        left: 30px;
        font-size: 25px;
        opacity: 0.27;
    }

    .focus_icon {
        position: absolute;
        z-index: 999;
        opacity: 0.45;
        top: 15px;
        left: 25px;
    }

</style>