<template>
	<div class="content-wrapper pt-4">
		<div class="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-2">
						<UploadPhoto :recordType="'userRecord'" :recordID="`${this.$route.params.id}`" :photo="profileIMG"></UploadPhoto>
					</div>
					<div class="col-md-10">
						<div class="form-group row">
							<label class="col-md-2 col-form-label">First Name</label>
							<div class="col-md-6">
								<div class="input-group">
									<input v-model="firstname" type="text" class="form-control" 
										:class="{ 'is-invalid' : errors.first('firstname') }" 
										name="firstname" v-validate="'required'" :disabled="isFirstNameDisabled">
									<span class="input-group-append">
										<button @click="isFirstNameDisabled ? isFirstNameDisabled=false : isFirstNameDisabled=true" 
											type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isFirstNameDisabled, 'btn-dark':isFirstNameDisabled}">
											<i v-if="isFirstNameDisabled" class="fas fa-lock"></i>
											<i v-else class="fas fa-lock-open"></i>
										</button>
										<button @click='updateRecord("firstname",firstname)' :disabled="isFirstNameDisabled" 
											type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i>
										</button>
									</span>
								</div>
								<small class="text-danger">{{ errors.first('firstname') }}</small>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-2 col-form-label">Last Name</label>
							<div class="col-md-6">
								<div class="input-group">
									<input v-model="lastname" type="text" class="form-control" 
										:class="{ 'is-invalid' : errors.first('lastname') }" 
										name="lastname" v-validate="'required'" :disabled="isLastNameDisabled">
									<span class="input-group-append">
										<button @click="isLastNameDisabled ? isLastNameDisabled=false : isLastNameDisabled=true" type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isLastNameDisabled, 'btn-dark':isLastNameDisabled}">
											<i v-if="isLastNameDisabled" class="fas fa-lock"></i>
											<i v-else class="fas fa-lock-open"></i>
										</button>
										<button @click='updateRecord("lastname",lastname)' :disabled="isLastNameDisabled" type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
									</span>
								</div>
								<small class="text-danger">{{ errors.first('lastname') }}</small>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-2 col-form-label">Nice Name</label>
							<div class="col-md-6">
								<div class="input-group">
									<input v-model="niceName" type="text" class="form-control" 
										:class="{ 'is-invalid' : errors.first('niceName') }" 
										name="niceName" v-validate="''" :disabled="isNiceNameDisabled">
									<span class="input-group-append">
										<button @click="isNiceNameDisabled ? isNiceNameDisabled=false : isNiceNameDisabled=true" 
											type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isNiceNameDisabled, 'btn-dark':isNiceNameDisabled}">
											<i v-if="isNiceNameDisabled" class="fas fa-lock"></i>
											<i v-else class="fas fa-lock-open"></i>
										</button>
										<button @click='updateRecord("nice_name",niceName)' :disabled="isNiceNameDisabled" 
											type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i>
										</button>
									</span>
								</div>
								<small class="text-danger">{{ errors.first('niceName') }}</small>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-2 col-form-label">Email / Username</label>
							<div class="col-md-6">
								<div class="input-group">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="fas fa-envelope"></i></span>
									</div>
									<input v-model="emailAd" type="text" class="form-control" disabled>
								</div>
							</div>
						</div>

						<div class="form-group row">
							<div class="col-md-10">
								<hr />
								<h4>Change Password</h4>
							</div>
						</div>

						<div v-if="is_show_error" class="form-group row">
							<div class="offset-2 col-md-6">
								<div class="jumbotron pt-4 pb-2 pl-2">
									<ul><li v-for="error in errorChecker" class="text-danger">{{ error }}</li></ul>
								</div>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-md-2 col-form-label">New Password</label>
							<div class="col-md-6">
								<div class="input-group">
									<input v-model="newPass" :type="passwordInputType" class="form-control" :class="{ 'is-invalid' : errors.first('password') }" 
										name="password" v-validate="'required|min:8'" placeholder="Enter new password">
									<span class="input-group-append">
										<button type="button" class="btn btn-flat btn-primary" @click="passwordInputType=='password' ? passwordInputType='text' : passwordInputType='password'">
											<i class="fas fa-eye"></i>
										</button>
									</span>
								</div>
								<small class="text-danger">{{ errors.first('password') }}</small>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-md-2 col-form-label">Confirm Password</label>
							<div class="col-md-6">
								<div class="input-group">
									<input v-model="confPass" :type="confPasswordInputType" class="form-control" :class="{ 'is-invalid' : errors.first('confirm_password') }" 
										name="confirm_password" v-validate="'required'" placeholder="Please confirm your new password">
									<span class="input-group-append">
										<button type="button" class="btn btn-flat btn-primary" @click="confPasswordInputType=='password' ? confPasswordInputType='text' : confPasswordInputType='password'">
											<i class="fas fa-eye"></i>
										</button>
									</span>
								</div>
								<small class="text-danger">{{ errors.first('confirm_password') }}</small>
								<small v-if="isResetConfirmMatch" class="text-danger">Confirm password did not match</small>
							</div>
						</div>

						<div class="form-group row">
							<div class="offset-2 col-md-6">
								<button class="btn btn-primary btn-sm float-right" type="submit" @click="saveResetPassword()">
									<i class="fas fa-save"></i> Save New Password
								</button>
								<button class="btn btn-dark btn-sm float-right mr-2" type="button" @click="generateRandomPass()">
									<i class="fas fa-sync"></i> Auto-generate Password
								</button>
							</div>
						</div>

						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	import { mapGetters } from 'vuex'
	import {Stretch} from 'vue-loading-spinner'
	import UploadPhoto from '../utilities/UploadPhoto'

	export default {
		name: 'view-user',
		components: {
			Stretch,
			UploadPhoto
		},
		data() {
			return {
				user: [],
				isFirstNameDisabled: true,
				isLastNameDisabled: true,
				isNiceNameDisabled: true,
				firstname: null,
				lastname: null,
				niceName: null,
				emailAd: null,
				profileIMG: null,
				passwordInputType: 'password',
				confPasswordInputType: 'password',
				errorChecker: [],
				is_show_error: false,
				newPass: null,
				confPass: null,

			}
		},
		created() {

			if(this.$route.params.id != this.currentUser.id) {
				this.$router.push('/user/'+this.currentUser.id);
			}

			this.preLoadUserInformation();


		},
		computed: {
			...mapGetters(['sectors']),
			currentUser() {
				return this.$store.getters.currentUser;
			},
			isConfirmMatch() {
				if(this.adminuser.password==this.adminuser.confPassword) {
					return false;
				}
				return true;
			},
			isResetConfirmMatch() {
				if(this.newPass==this.confPass) {
					return false;
				}
				return true;
			}
		},
		methods: {
			async preLoadUserInformation() {

				await axios.get(`/api/user/${this.$route.params.id}`, {
						headers: { "Authorization": `Bearer ${this.currentUser.token}`}
					})
					.then((response) => {
						this.firstname = response.data.user.firstname;
						this.lastname = response.data.user.lastname;
						this.niceName = response.data.user.nice_name;
						this.emailAd = response.data.user.email;
						this.profileIMG = response.data.user.profile_pic;
					})
					.catch((error) => {
						console.log(error);
					})
			},

			async updateRecord(columnName, newData) {

				let updatedRecord = { 
						columnName: columnName,
						'newData': newData,
						'user_id': this.currentUser.id
					}

				await axios.post('/api/user/update', updatedRecord, {
							headers: { "Authorization": `Bearer ${this.currentUser.token}`},
						})
						.then((response) => {
							if(response.data.error) {
								this.$swal.fire({
								  icon: 'error',
								  title: 'Oops...',
								  text: response.data.error,
								});
							} 
							else {
								this.$swal.fire({
									position: 'top-end',
									icon: 'success',
									title: 'Record updated successfully',
									showConfirmButton: false,
									timer: 1500
								});
								this.employer = response.data.employer;
								if(response.data.user.columnName == 'firstname') {
									this.firstname==response.data.user.newData;
									this.isFirstNameDisabled = true;
								} 
								else if(response.data.user.columnName == 'lastname') {
									this.lastname==response.data.user.newData;
									this.isLastNameDisabled = true;
								}
								else if(response.data.user.columnName == 'nice_name') {
									this.niceName==response.data.user.newData;
									this.isNiceNameDisabled = true;
								}
							}
						})
						.catch((error) => {
							console.log(error);
						})
			},

			generateRandomPass() {
				this.newPass = Math.random().toString(36).substring(5);
			},
			saveResetPassword() {

				let newData = {
					'user_id': this.currentUser.id,
					'newpass': this.newPass,
					'confPass': this.confPass,
				};

				this.$validator.validateAll()
					.then((result) => {
						if(!this.errors.any()) {
							axios.post('/api/user/reset_password', {headers: { "Authorization": `Bearer ${this.currentUser.token}`}, newData})
								.then((response) => {
									if(response.data.errors) {
										this.is_show_error = true;
										this.errorChecker = response.data.errors;
									}
									else {
										if(response.data.status) {
											this.$swal.fire({
												position: 'top-end',
												icon: 'success',
												title: 'Password changed successfully',
												showConfirmButton: false,
												timer: 1500
											});
										} else {
											console.log(response.data.status);
										}

										this.newPass = null;
										this.confPass = null;

										this.$swal.fire({
											position: 'center',
											icon: 'error',
											title: 'You will be log-out. Please login with your new password',
											timer: 1500
										});
										setTimeout(() =>this.$store.dispatch('logout'), 1500);
										setTimeout(() => this.$router.push('login'), 1500);

									}
								})
								.catch((error) => {
									console.log(error);
								})
						} 
						else {
							this.is_show_error = true;
							this.errorChecker = this.errors.all();
						}
					})
					.catch((errors) => {
						console.log(errors);
					});
			},
		}
		
	}
</script>
<style scoped>
	.photo_img {
		object-fit: cover;
		width: inherit;
	}
</style>