<template>
	<div>
		<div class="card card-dark card-outline">
			<div class="card-header py-3">
				<h5 class="m-0"><strong>System Activities</strong></h5>
			</div>
			<div class="card-body table-responsive px-0" style="height: 503px;">
				<v-table 
					:data="logs" 
					class="table table-head-fixed table-bordered table-hover" 
					:currentPage.sync="currentPage" 
					:pageSize="15" @totalPagesChanged="totalPages = $event">
					<tbody slot="body" slot-scope="{displayData}">
						<tr v-for="log in displayData" :key="log.id">
							<td class="py-0">
								<a :href="'/log/'+log.id" class="nav-link text-muted custom_link">
									<small>{{ log.comment }} </small>
									<small class="float-right p-2 font-weight-normal badge badge-pill bg-secondary text-white">
										{{ dateCounter(log.created_at) }}
									</small>
								</a>
							</td>
						</tr>
					</tbody>
				</v-table>
			</div>
			<div class="card-footer pt-4 pb-2">
				<smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" class="float-right pagination-sm" />
				<router-link to="/employers" class="btn btn-dark btn-sm float-left"><i class="fas fa-eye"></i>
					&nbsp;<small>VIEW ALL</small>
				</router-link>
			</div>
		</div>
	</div>
</template>
<script>
	
	import moment from 'moment'
	import { mapGetters } from 'vuex'

	export default {
		name: 'activity-logs',
		data() {
			return {
				currentPage: 1,
				totalPages: 0,
			}
		},
		created() {
			this.getchMyLogs();
		},
		computed: {
			...mapGetters(['logs']),
			currentUser() {
				return this.$store.getters.currentUser;
			}
		},
		methods: {
			dateFormatter(data) {
				return moment(data).format("MMM DD, YYYY");
			},
			dateCounter(data) {
				return moment(data,"YYYYMMDD").fromNow();
			},
			async getchMyLogs() {
				await this.$store.dispatch('fetchMyLogs', this.currentUser.employer_id);
			},
		}
	}

</script>
<style scoped></style>
