<template>
	<div>
		<div class="card card-default">
			<div class="card-header">
				<h6 class="float-left mb-0 font-weight-bold">Resume Uploaded</h6>
				<div class="card-tools">
				
					<b-button squared @click="resetUploadBtn" variant="link" size="sm">
						<small><i class="fas fa-plus"></i> ADD NEW</small>
					</b-button>
				</div>
			</div>
			<v-table :data="appResumes" class="table table-sm table-bordered mb-0" :currentPage.sync="currentPage" 
				:pageSize="10" @totalPagesChanged="totalPages = $event">
				<tbody slot="body" slot-scope="{displayData}">
					<tr v-for="cv in displayData" :key="cv.id" class="bg-white">
						<td width="5%" class="text-center align-middle">
							<h5 class="mb-0 py-1"><i class="far fa-file-pdf"></i></h5>
						</td>
						<td class="align-middle">{{ cv.title }}</td>
						<td class="align-middle">{{ cv.overview.length > 60 ? cv.overview.substring(0,60) + '...' : cv.overview }}</td>
						<td width="5%" class="text-center align-middle">
							<a :href="upload_path + cv.filepath" class="text-primary" target="_blank"><i class="fas fa-download"></i></a>
						</td>
					</tr>
				</tbody>
			</v-table>
		</div>
		<b-modal size="lg" title="Upload Resume" v-model="modalShow">
			<b-container fluid>
				<b-row>
					<b-col cols="12">
						<div v-if="is_show_error" class="form-group row">
							<div class="offset-2 col-md-10">
								<div class="jumbotron pt-4 pb-2 pl-2">
									<ul><li v-for="error in errorChecker" class="text-danger">{{ error }}</li></ul>
								</div>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-2 col-form-label">Label</label>
							<div class="col-md-10">
								<input v-model="title" type="text" class="form-control" 
									:class="{ 'is-invalid' : errors.first('title') }" 
									name="title" v-validate="'required'" placeholder="Label name or title of the document">
								<small class="text-danger">{{ errors.first('title') }}</small>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-2 col-form-label">Overview</label>
							<div class="col-md-10">
								<textarea row="3" v-model="overview" class="form-control" 
										:class="{ 'is-invalid' : errors.first('overview') }" 
										name="overview" v-validate="'required'" placeholder="Just input N/A if not applicable">
								</textarea>
								<small class="text-danger">{{ errors.first('overview') }}</small>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-2 col-form-label">Attach CV</label>
							<div class="col-md-10">
								<label for="file-upload" class="custom-file-upload w-100" :class="{ 'isAttached' : isFileAttached }">
								    <i class="far fa-file-pdf"></i>&nbsp; Upload Resume (PDF format only | Size limit: 3MB)
								</label>
								<small v-if="isFileAttached" class="text-danger">Attachment must not be empty.</small>
								<input id="file-upload" type="file" @change="uploadPDF" class="form-control" accept="application/pdf"/>
								
								<embed :src="uploadfilepath" width="100%" height="250" style="border:2px solid #ccc;">

							</div>
						</div>
					</b-col>					
				</b-row>
			</b-container>

			<template #modal-footer>
				<div class="w-100">
					<b-button variant="primary" type="submit" size="sm" class="float-right" @click="uploadRessume()">
						<i class="fas fa-upload"></i> Upload Resume
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>
<script>
	export default {
		name: 'applicant-resume',
		props: ['appResumes', 'recordID'],
		data() {
			return {
				title: null,
				overview: null,
				pdf_file: {},
				upload_path: '/images/uploads/',
				errorChecker: [],
				is_show_error: false,
				isFileAttached: false,
				uploadfilepath: null,
				modalShow: false,
				currentPage: 1,
				totalPages: 0,
			}
		},
		computed: {
			currentUser() {
				return this.$store.getters.currentUser;
			},
		},
		methods: {
			resetUploadBtn() {
				this.modalShow = !this.modalShow;
				this.uploadfilepath = null;
				this.title = '';
				this.overview = '';
				this.pdf_file = {};
				this.isFileAttached = false;
			},

			 uploadPDF(e) {
			 	this.isFileAttached = false;
                this.pdf_file = event.target.files[0];
                this.uploadfilepath = window.URL.createObjectURL(event.target.files[0]);
            },
            uploadRessume() {

            	this.isFileAttached = false;

            	if(this.uploadfilepath==null) {
            		this.isFileAttached = true;
            		return false;
            	}

            	this.$validator.validateAll()
					.then((result) => {

						if(!this.errors.any()) {
							let apiUrl = null;
							const formData = new FormData();
							const config = {"headers": 
												{ 'content-type': 'multipart/form-data', "Authorization": "Bearer "+this.currentUser.token }	
						                	}
						    apiUrl = '/api/resume/upload';
							formData.append('title', this.title);
							formData.append('overview', this.overview);
							formData.append('app_id', this.recordID);
							formData.append('resumeDetails', this.pdf_file);

							axios.post(apiUrl, formData, config)
								.then((response) => {
									this.$swal.fire({
										position: 'top-end',
										icon: 'success',
										title: 'Photo uploaded successfully',
										showConfirmButton: false,
										timer: 1500
									});
									this.resetUploadBtn();
									this.appResumes.push(response.data.resume);
								})
								.catch((error) => {
									console.log(error);
								})
						} 
						else {
							this.is_show_error = true;
							this.errorChecker = this.errors.all();
						}
					})
					.catch((errors) => {
						console.log(errors);
					});
            }
		}
	}
</script>
<style scoped>
	.resumeStyle {
		object-fit: cover;
		width: 50px;
		height: 50px;
	}

	input[type="file"] {
	    display: none;
	}

	.custom-file-upload {
	    border: 1px solid #ccc;
	    display: inline-block;
	    padding: 6px 12px;
	    cursor: pointer;
	}

	.isAttached {
		border:1px solid red;
	}
</style>