<template>
	<div>
		<div class="container-fluid">
			<div class="row justify-content-center mt-4">
				<div class="col-md-10">
					<div class="site_title clearfix text-center" style="padding: 60px;">
						<img src="/images/logo.png" class="img_logo pr-2" style="width: 75%;">
					</div>
				</div>
			</div>

			<!-- navigation  -->
			<div class="row justify-content-center nav_bg">
				<div class="col-md-10">
					<Navigation></Navigation>
				</div>
			</div>
			<br />
			<div class="row justify-content-center">
				<div class="col-md-8 pt-4">
					<div class="row">
						<div class="col-md-3">
							<img :src="employer.photo_img === null ? '/images/default_user.png' : employer.photo_img"
								class="img-fluid">
						</div>
						<div class="col-md-9">
							<h4>{{ job.title }}</h4>
							<p class="mb-0">Posted by : {{ employer.name }}</p>
							<p class="text-muted"><small>Posted last {{ dateFormatter(job.created_at) }} <i>({{
								dateCounter(job.created_at) }})</i></small></p>
							<hr />
							<h5>Job Description</h5>
							<span v-html="job.description"></span>
							<br />
							<br />

							<h5>Responsibilities</h5>
							<span v-html="job.responsibilities"></span>
							<br />
							<br />
							<h5>Qualifications</h5>
							<span v-html="job.qualifications"></span>
							<br />
							<br />
							<h5>Additional Information</h5>
							<ul>
								<li>Career Level : {{ job.career_level }}</li>
								<li>Years Experience : {{ job.yrs_exp }}</li>
								<li>Employment Location : {{ job.location }}</li>
								<li>Salary Offer : {{ job.salary }}</li>
							</ul>
							<br />
							<p class="mb-1"><small><i>Category:</i> <span class="badge badge-pill badge-success p-2">{{
								job.clasification }}</span></small></p>
							<template v-if="job.subclasification !== null">
								<small class="text-muted"><i>Tags: </i> <span
										v-for="(subclass, index) in job.subclasification"> <span v-if="index !== 0">,</span>
										{{ subclass.name }}</span></small>
							</template>
						</div>
					</div>
				</div>
				<div class="col-md-8">
					<hr />
					<button class="btn btn-lg btn-danger float-right" @click="openModal()">SUMBIT APPLICATION</button>
				</div>
			</div>

			<!-- footer -->
			<pageFooter></pageFooter>
		</div>

		<b-modal size="lg" title="Submit Application" v-model="showModal" hide-footer>
			<form role="form" @submit.prevent="sendApplication()" enctype="multipart/form-data" method="post">
				<b-container fluid>
					<b-row>
						<b-col cols="12">
							<div class="form-group row">
								<label class="col-md-2 col-form-label">First Name:</label>
								<div class="col-md-10">
									<div class="input-group">
										<input v-model="application.fname" type="text" class="form-control"
											:class="{ 'is-invalid': errors.first('firstname') }" name="firstname"
											v-validate="'required'">
									</div>
									<small class="text-danger">{{ errors.first('firstname') }}</small>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label">Last Name:</label>
								<div class="col-md-10">
									<div class="input-group">
										<input v-model="application.lname" type="text" class="form-control"
											:class="{ 'is-invalid': errors.first('lastname') }" name="lastname"
											v-validate="'required'">
									</div>
									<small class="text-danger">{{ errors.first('lastname') }}</small>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label">Phone Number:</label>
								<div class="col-md-10">
									<div class="input-group">
										<input v-model="application.phoneNumber" type="text" class="form-control"
											:class="{ 'is-invalid': errors.first('phoneNumber') }" name="phoneNumber"
											v-validate="'required'">
									</div>
									<small class="text-danger">{{ errors.first('phoneNumber') }}</small>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label">Email:</label>
								<div class="col-md-10">
									<div class="input-group">
										<input v-model="application.emailAddress" type="email" class="form-control"
											:class="{ 'is-invalid': errors.first('emailAddress') }" name="emailAddress"
											v-validate="'required'">
									</div>
									<small class="text-danger">{{ errors.first('emailAddress') }}</small>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label">Subject:</label>
								<div class="col-md-10">
									<div class="input-group">
										<input v-model="application.title" type="text" class="form-control"
											:class="{ 'is-invalid': errors.first('application_title') }"
											name="application_title" v-validate="'required'"
											placeholder="Application message title">
									</div>
									<small class="text-danger">{{ errors.first('application_title') }}</small>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label">Message:</label>
								<div class="col-md-10">
									<div class="input-group">
										<textarea v-model="application.message" class="form-control" rows="5"
											:class="{ 'is-invalid': errors.first('application_msg') }"
											name="application_msg" v-validate="''"
											placeholder="Compose application message (optional)"></textarea>
									</div>
									<small class="text-danger">{{ errors.first('application_msg') }}</small>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label">Resume <i class="fas fa-paperclip"></i>:</label>
								<div class="col-md-10">

									<template v-if="currentUser === null">
										<label for="file-upload" class="custom-file-upload w-100"
											:class="{ 'isAttached': isFileAttached }">
											<i class="far fa-file-pdf"></i>&nbsp; Upload Resume (PDF format only | Size
											limit: 3MB)
										</label>
										<small v-if="isFileAttached" class="text-danger">Attachment must not be
											empty.</small>
										<input id="file-upload" type="file" @change="uploadPDF()"
											:class="{ 'is-invalid': errors.first('resume') }" class="form-control"
											name="resume" accept="application/pdf" v-validate="'required'" />
										<small class="text-danger">{{ errors.first('resume') }}</small>

										<embed v-if="uploadfilepath" :src="uploadfilepath" width="100%" height="250"
											style="border:2px solid #ccc; margin-top: 20px !important;">
									</template>
									<template v-else>
										<multiselect v-model="application.resume" name="resume" v-validate="'required'"
											:class="{ 'danger_border': errors.first('resume') }" :options="resume"
											:searchable="false" label="title" track-by="title" :close-on-select="true"
											:show-labels="false" placeholder="Select resume/CV you want to attach...">
										</multiselect>
										<small class="text-danger">{{ errors.first('resume') }}</small>
									</template>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-md-12">
									<br />
									<b-button variant="dark" type="reset" class="float-right ml-2"
										@click="uploadfilepath = null">RESET</b-button>
									<b-button variant="danger" type="submit" class="float-right">
										<i class="fas fa-paper-plane"></i> SEND
									</b-button>
								</div>
							</div>
						</b-col>
					</b-row>
				</b-container>
			</form>
		</b-modal>

	</div>
</template>
<script>
import Navigation from '../../layout/Navigation';
import pageFooter from '../../layout/Footer';
import Multiselect from 'vue-multiselect'

import moment from 'moment';

export default {
	name: "show-job",
	components: {
		Navigation,
		pageFooter,
		Multiselect
	},
	data() {
		return {
			job: [],
			employer: [],
			showModal: false,
			isFileAttached: false,
			uploadfilepath: null,
			pdf_file: {},
			application: {
				'fname': null,
				'lname': null,
				'emailAddress': null,
				'phoneNumber': null,
				'title': null,
				'message': null,
				'resume': null
			},
			resume: [
				{
					'id': 1,
					'title': "Web Development Resume",
					'filepath': "https://via.placeholder.com/640x480.png/00cc00?text=MyResume"
				},
				{
					'id': 2,
					'title': "Business Development Officer",
					'filepath': "https://via.placeholder.com/640x480.png/0eee00?text=MyResume"
				},
				{
					'id': 3,
					'title': "Network Administrator",
					'filepath': "https://via.placeholder.com/640x480.png/042540?text=MyResume"
				}
			]
		}
	},
	created() {
		this.fetchJob();
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		}
	},
	methods: {
		resetUploadBtn() {
			this.uploadfilepath = null;
			this.application = {};
			this.pdf_file = {};
			this.isFileAttached = false;
		},

		uploadPDF(e) {
			this.isFileAttached = false;
			this.pdf_file = event.target.files[0];
			this.uploadfilepath = window.URL.createObjectURL(event.target.files[0]);
		},
		async fetchJob() {
			await axios.get('/api/job/' + this.$route.params.slug)
				.then((response) => {
					if (response.data.job == null) {
						this.$router.push('/')
					}
					else {
						this.job = response.data.job;
						this.employer = response.data.job.employer;
					}
				})
				.catch((error) => {
					console.log(error);
				})
		},
		openModal() {
			this.showModal = !this.showModal;
			this.application.title = "Applying for " + this.job.title;
		},
		dateFormatter(data) {
			return moment(data).format("MMM DD, YYYY");
		},
		dateCounter(data) {
			return moment(data, "YYYYMMDD").fromNow();
		},

		sendApplication() {

			this.$validator.validateAll()
				.then(() => {
					if (this.errors.any()) {
						this.is_show_error = true;
						this.errorChecker = this.errors.all();
						return false;
					}

					const formData = new FormData();
					formData.append('attached_cv', this.pdf_file);
					formData.append('job_slug', this.$route.params.slug);
					formData.append('application', JSON.stringify(this.application));

					const config = {
						"headers":
							{ 
								'content-type': 'multipart/form-data',
								'X-CSRF-TOKEN': document.head.querySelector('meta[name="CSRF-TOKEN"]').getAttribute('content')
							}
					}

					axios.post('/api/sendApplication', formData, config)
						.then((response) => {
							this.$swal.fire({
								position: 'top-end',
								icon: 'success',
								title: 'Application submitted successfully. You will be contacted via email soon. ',
								showConfirmButton: false,
								timer: 3000
							});

							this.showModal = false;
							this.resetUploadBtn();
						})
						.catch((error) => {
							console.log(error);
						});
				})
		}

	}

}
</script>
<style scoped>
	.multiselect__tags {
		padding: 17px 40px 0px 0px !important;
	}

	.isAttached {
		border: 1px solid red;
	}
</style>