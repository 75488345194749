<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>

	import { mapGetters } from 'vuex'

	import TopNavigation from '../layout/TopNavigation.vue'
	import Sidebar from '../layout/Sidebar.vue'

	export default {

		name: 'main-app',

		components: {
			TopNavigation,
			Sidebar
		},

		computed: {
			...mapGetters(['isLoggedIn', 'currentUser'])
		},

	}
	
</script>

<style scoped>
	
</style>
