<template>
	<div class="content-wrapper pt-4">
		<div class="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-8">
						<div class="row">
							<div class="col-md-12 mb-3">
								<h5>About the Applicant</h5>
							</div>
							<div class="col-md-3">
								<img v-if="applicant.profile_img!=null" :src="applicant.profile_img" class="img-fluid border border-secondary" />
								<img v-else :src="'/images/default_user.png'" class="img-fluid border border-secondary" />
							</div>
							<div class="col-md-9">
								<table class="table table-striped">
									<tr>
										<td width="25%">First Name</td>
										<td>: {{ applicant.firstname }}</td>
									</tr>
									<tr>
										<td>Last Name</td>
										<td>: {{ applicant.lastname }}</td>
									</tr>
									<tr>
										<td>Phone #</td>
										<td>: {{ applicant.phone }}</td>
									</tr>
									<tr>
										<td>Email Address</td>
										<td>: 
											{{ applicant.email }} 
											<a :href="'mailto:'+applicant.email" class="float-right">
												<i class="fas fa-envelope"></i>
											</a>
										</td>
									</tr>
									<tr>
										<td>
											<a :href="/applicant/+applicant.app_id" class="btn btn-primary btn-sm"><i class="fas fa-user"></i> View Profile</a>
										</td>
										<td>&nbsp;</td>
									</tr>
								</table>
							</div>
							<div class="col-md-12">
								<hr />
								<h4 class="mb-4">Application Letter</h4>
								<wysiwyg v-model="applicant.short_msg" class="bg-white" />
								<br />
								<div class="btn-group btn-group-toggle" data-toggle="buttons">
									<label class="btn btn-secondary" :class="{ 'btn-danger':applicant.status=='pending' }">
										<input :checked="applicant.status=='pending'" @change="changeStatus(setStatus)" v-model="setStatus" value="pending" type="radio"> PENDING
									</label>
									<label class="btn btn-secondary text-white" :class="{ 'btn-warning':applicant.status=='shortlisted' }">
										<input :checked="applicant.status=='shortlisted'" @change="changeStatus(setStatus)" v-model="setStatus" value="shortlisted" type="radio"> SHORTLISTED
									</label>
									<label class="btn btn-secondary" :class="{ 'btn-info':applicant.status=='interview' }">
										<input :checked="applicant.status=='interview'" @change="changeStatus(setStatus)" v-model="setStatus" value="interview" type="radio"> FOR INTERVIEW
									</label>
									<label class="btn btn-secondary" :class="{ 'btn-success':applicant.status=='accepted' }">
										<input :checked="applicant.status=='hired'" @change="changeStatus(setStatus)" v-model="setStatus" value="accepted" type="radio"> ACCEPTED
									</label>
									<label class="btn btn-secondary" :class="{ 'btn-danger':applicant.status=='close' }">
										<input :checked="applicant.status=='close'" @change="changeStatus(setStatus)" v-model="setStatus" value="close" type="radio"> CLOSE
									</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="row">
							<div class="col-md-12 mb-3">
								<h5>Application Resume</h5>
							</div>
							<div v-if="applicant.resume_attachment" class="col-md-12 mb-3">
								<embed :src="applicant.resume_attachment" width="100%" height="530" style="border:2px solid #ccc;">
							</div>
							<div v-else class="col-md-12 mb-3 bg-white" style="height:530px; border:2px solid #ccc;">
								<center><p class="text-muted"><i>--No Resume attached--</i></p></center>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import { mapGetters } from 'vuex'
	import {Stretch} from 'vue-loading-spinner'
	import moment from 'moment'

	export default {
		name: 'job-application',
		components: {
			Stretch
		},
		data() {
			return {
				uploadPath: '/images/uploads/',
				currentPage: 1,
				totalPages: 0,
				filters: {
					name: { value: '', keys: ['jobs.employer', 'title', 'type', 'industry'] }
				},
				isLoading : false,
				applicant: [],
				setStatus: null
			}
		},

		async created() {
			
			await this.getApplication();

			if(this.currentUser.employer_id !== this.$store.getters.application.employer_id) {
				this.$swal.fire({
					position: 'center',
					icon: 'error',
					title: 'Oops..PAGE NOT FOUND',
					timer: 1500
				});
				setTimeout(() => this.$router.push('/404'), 1500);
			}

			this.applicant = this.$store.getters.application;


		},
		computed: {
			...mapGetters(['jobs', 'application']),
			currentUser() {
				return this.$store.getters.currentUser;
			}
		},
		methods: {
			async getApplication() {
				await this.$store.dispatch('getApplication', this.$route.params.id);
			},
			json_parser(jsonData) {
				return JSON.parse(jsonData);
			},
			dateFormatter(data) {
				return moment(data).format("MMM DD, YYYY");
			},
			dateCounter(data) {
				return moment(data,"YYYYMMDD").fromNow();
			},
			async changeStatus(status) {

				if(this.application.status == status) 
					return;

				let application = {
						'application_id' : this.application.id,
						'job_id' : this.application.job_id,
						'status' : status
					}
				const swalWithBootstrapButtons = this.$swal.mixin({
					customClass: {
						confirmButton: 'btn btn-success',
						cancelButton: 'btn btn-danger'
					},
					buttonsStyling: false
				})
				await swalWithBootstrapButtons.fire({
					title: 'Update application status?',
					text: "Do you want change the application status to '"+status+"'",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, confirm!',
					cancelButtonText: 'No, cancel!',
					reverseButtons: true
				})
				.then((result) => {
					if (result.isConfirmed) {

						axios.post('/api/application/update_status', application, {
							headers: { "Authorization": `Bearer ${this.currentUser.token}`}
						})
						.then((response) => {
							this.application.status = response.data.status;
							swalWithBootstrapButtons.fire('Updated!','Application status updated successfully.','success');
						})
						.catch((error) => {
							console.log(error);
						})



					} 
				})
			}
		}
	}
</script>

<style scoped>
	.photo_img {
		object-fit: cover;
		width: 32px;
		height: 32px;
	}
</style>