<template>
	<div>
		<div class="row mb-3">
			<div class="col-md-12">
				<router-link :to="'/employer/' + currentUser.employer_id + '/job/new'" class="btn btn-primary btn-sm float-right mt-1">
					<i class="fas fa-plus"></i> POST NEW JOB
				</router-link>
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				<h3 class="card-title mt-2">JOB APPLICATIONS</h3>
				<div class="card-tools" style="rigth:0rem !important;">
					<div class="input-group input-group-sm mt-2" style="width: 300px;">
						<input type="text" v-model="filters.name.value" class="form-control form-control-sm" placeholder="Search Job" aria-label="Search Employers" aria-describedby="basic-addon1">
						<div class="input-group-append bg-primary">
							<span class="input-group-text bg-primary" id="basic-addon1"><i class="fas fa-search"></i></span>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body table-responsive p-0" style="height: 648px;">
				<v-table :data="jobs" class="table table-head-fixed table-bordered table-hover" :currentPage.sync="currentPage" 
					:pageSize="10" @totalPagesChanged="totalPages = $event" :filters="filters">
					<thead slot="head">
						<tr>
							<th>Title(Position)</th>
							<th>Classification</th>
							<th>Type</th>
							<th>Date Posted</th>
							<th class="text-center" width="3%">Status</th>
							<th class="text-center" width="5%">Searcheable</th>
							<th width="2%">&nbsp;</th>
						</tr>
					</thead>
					<tbody slot="body" slot-scope="{displayData}">
						<template v-if="!jobs.length">
							<tr>
								<td colspan="10" class="text-center py-4">
									<center><Stretch></Stretch></center>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr v-for="job in displayData" :key="job.id">
								<td class="align-middle">
									<img :src="job.job_img==null ? '/images/default_user.png' : job.job_img" class="img-circle photo_img" />
									{{ job.title }}
								</td>
								<td class="align-middle">{{ job.clasification ? job.clasification : 'N/A' }}</td>
								<td class="align-middle">{{ job.job_type }}</td>
								<td class="align-middle">
									{{ dateFormatter(job.created_at) }} <i>({{ dateCounter(job.created_at) }})</i>
								</td>
								<td class="align-middle text-center">
									<span class="badge badge-pill p-2" :class="{ 'badge-success': job.status==1, 'badge-dark': job.status==0}">
									{{ job.status==1 ? 'OPEN' : 'CLOSE' }}
								</span>
								</td>
								<td class="align-middle text-center">
									<span class="badge badge-pill p-2" :class="{ 'badge-success': job.is_searcheable==1, 'badge-dark': job.is_searcheable==0 }">
										{{ job.is_searcheable==1 ? 'YES' : 'NO' }}
									</span>
								</td>
								
								<td class="text-center align-middle">
									<router-link :to="`/job/${job.slug}`" class="text-primary">
										<i class="fas fa-arrow-right"></i>
									</router-link>
								</td>
							</tr>
							<tr v-if="displayData.length==0 || filters.name.length==0">
								<td colspan="10" class="text-center">No Jobs found</td>
							</tr>
						</template>
					</tbody>
				</v-table>
			</div>
		</div>
		<smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" class="float-right" />
	</div>
</template>

<script>

	import { mapGetters } from 'vuex'
	import {Stretch} from 'vue-loading-spinner'
	import moment from 'moment'

	export default {
		name: 'job-list',
		components: {
			Stretch
		},
		data() {
			return {
				uploadPath: '/images/uploads/',
				currentPage: 1,
				totalPages: 0,
				filters: {
					name: { value: '', keys: ['jobs.employer', 'title', 'type', 'industry'] }
				},
				isLoading : false
			}
		},

		created() {
			this.$store.dispatch('fetchMyJobs');
		},
		computed: {
			...mapGetters(['jobs']),
			currentUser() {
				return this.$store.getters.currentUser;
			},
		},
		methods: {
			json_parser(jsonData) {
				return JSON.parse(jsonData);
			},
			dateFormatter(data) {
				return moment(data).format("MMM DD, YYYY");
			},
			dateCounter(data) {
				return moment(data,"YYYYMMDD").fromNow();
			},
		}
	}
</script>

<style scoped>
	.photo_img {
		object-fit: cover;
		width: 32px;
		height: 32px;
	}
</style>