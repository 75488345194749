<template>
	<div>
		<div class="card card-default">
			<div class="card-header">
				<h6 class="float-left mb-0 font-weight-bold">User Administrator</h6>
				<div class="card-tools">
				
					<b-button squared @click="resetUserForm()" variant="link" size="sm">
						<small><i class="fas fa-plus"></i> ADD USER</small>
					</b-button>
				</div>
			</div>
			<v-table :data="AdminUsers" class="table table-sm table-bordered mb-0">
				<thead slot="head">
					<tr>
						<th>Name</th>
						<th>Username</th>
						<th colspan="2">&nbsp</th>
					</tr>
				</thead>
				<tbody slot="body" slot-scope="{displayData}">
					<tr v-for="user in displayData" :key="user.id" class="bg-white">
						<td class="text-left align-middle">
							<img :src="user.profile_pic==null ? '/images/default_user.png' : user.profile_pic" class="img-circle photo_img" />
							{{ user.firstname }} {{ user.lastname }}
						</td>
						<td class="align-middle">{{ user.email }}</td>
						<td width="10%" class="text-center align-middle">
							<b-button squared variant="link" size="sm" class="p-0 mr-2" @click="resetPasswordForm(user)">
								<i class="fas fa-redo text-dark"></i>
							</b-button>
							<button type="button" class="btn btn-link btn-sm p-0 mr-2" @click="changeStatus(user)">
								<i v-if="user.status==1" class="fas fa-arrow-circle-up text-success custom-size"></i>
								<i v-else class="fas fa-arrow-circle-down text-danger p-0 custom-size"></i>
							</button>
							<button disabled class="btn btn-link btn-sm p-0"><i class="fas fa-times text-danger custom-size"></i></button>
						</td>
					</tr>
				</tbody>
			</v-table>
		</div>

		<b-modal size="lg" title="Add New User" v-model="addUserModalShow">
			<b-container fluid>
				<b-row>
					<b-col cols="12">
						<div v-if="is_show_error" class="form-group row">
							<div class="offset-3 col-md-9">
								<div class="jumbotron pt-4 pb-2 pl-2">
									<ul v-if="errorChecker.length"><li v-for="error in errorChecker" class="text-danger">{{ error }}</li></ul>
									<p v-else class="text-danger pl-2">{{ customErrorMsg }}</p>
								</div>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label">Firstname</label>
							<div class="col-md-9">
								<input v-model="adminuser.firstname" type="text" class="form-control" 
									:class="{ 'is-invalid' : errors.first('firstname') }" 
									name="firstname" v-validate="'required'" placeholder="First Name">
								<small class="text-danger">{{ errors.first('firstname') }}</small>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label">Lastname</label>
							<div class="col-md-9">
								<input v-model="adminuser.lastname" type="text" class="form-control" 
									:class="{ 'is-invalid' : errors.first('lastname') }" 
									name="lastname" v-validate="'required'" placeholder="Last Name">
								<small class="text-danger">{{ errors.first('lastname') }}</small>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label">Nice Name</label>
							<div class="col-md-9">
								<input v-model="adminuser.nice_name" type="text" class="form-control" 
									:class="{ 'is-invalid' : errors.first('nice_name') }" 
									name="nice_name" v-validate="''" placeholder="(optional) Enter your pretty name">
								<small class="text-danger">{{ errors.first('nice_name') }}</small>
							</div>
						</div>
						<div class="form-group row">
							<div class="col-md-12"><hr /></div>
						</div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label">Email Address</label>
							<div class="col-md-9">
								<input v-model="adminuser.email" type="email" class="form-control" 
									:class="{ 'is-invalid' : errors.first('email') }" 
									name="email" v-validate="'required|email'" placeholder="Email Address">
								<small class="text-danger">{{ errors.first('email') }}</small>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label">Password</label>
							<div class="col-md-9">
								<div class="input-group">
									<input v-model="adminuser.password" :type="passwordInputType" class="form-control" :class="{ 'is-invalid' : errors.first('password') }" 
										name="password" v-validate="'required|min:8'" placeholder="Enter new password">
									<span class="input-group-append">
										<button type="button" class="btn btn-flat btn-primary" @click="passwordInputType=='password' ? passwordInputType='text' : passwordInputType='password'">
											<i class="fas fa-eye"></i>
										</button>
									</span>
								</div>
								<small class="text-danger">{{ errors.first('password') }}</small>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label">Confirm Password</label>
							<div class="col-md-9">
								<div class="input-group">
									<input v-model="adminuser.confPassword" :type="confPasswordInputType" class="form-control" :class="{ 'is-invalid' : errors.first('confirm_password') }" 
										name="confirm_password" v-validate="'required'" placeholder="Please confirm your new password">
									<span class="input-group-append">
										<button type="button" class="btn btn-flat btn-primary" @click="confPasswordInputType=='password' ? confPasswordInputType='text' : confPasswordInputType='password'">
											<i class="fas fa-eye"></i>
										</button>
									</span>
								</div>
								<small class="text-danger">{{ errors.first('confirm_password') }}</small>
								<small v-if="isConfirmMatch" class="text-danger">Confirm password did not match</small>
							</div>
						</div>

					</b-col>					
				</b-row>
			</b-container>

			<template #modal-footer>
				<b-row>
					<b-col cols="12">
						<b-button variant="primary" type="submit" size="lg" class="float-right" @click="createNewUser()">
							<i class="fas fa-save"></i> SAVE
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>

		<b-modal size="lg" title="Reset Password" v-model="resetPasswordModalShow">
			<b-container fluid>
				<b-row>
					<b-col cols="12">
						<div v-if="is_show_error" class="form-group row">
							<div class="offset-2 col-md-10">
								<div class="jumbotron pt-4 pb-2 pl-2">
									<ul><li v-for="error in errorChecker" class="text-danger">{{ error }}</li></ul>
								</div>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label">New Password</label>
							<div class="col-md-9">
								<div class="input-group">
									<input v-model="newPass" :type="passwordInputType" class="form-control" :class="{ 'is-invalid' : errors.first('password') }" 
										name="password" v-validate="'required|min:8'" placeholder="Enter new password">
									<span class="input-group-append">
										<button type="button" class="btn btn-flat btn-primary" @click="passwordInputType=='password' ? passwordInputType='text' : passwordInputType='password'">
											<i class="fas fa-eye"></i>
										</button>
									</span>
								</div>
								<small class="text-danger">{{ errors.first('password') }}</small>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label">Confirm Password</label>
							<div class="col-md-9">
								<div class="input-group">
									<input v-model="confPass" :type="confPasswordInputType" class="form-control" :class="{ 'is-invalid' : errors.first('confirm_password') }" 
										name="confirm_password" v-validate="'required'" placeholder="Please confirm your new password">
									<span class="input-group-append">
										<button type="button" class="btn btn-flat btn-primary" @click="confPasswordInputType=='password' ? confPasswordInputType='text' : confPasswordInputType='password'">
											<i class="fas fa-eye"></i>
										</button>
									</span>
								</div>
								<small class="text-danger">{{ errors.first('confirm_password') }}</small>
								<small v-if="isResetConfirmMatch" class="text-danger">Confirm password did not match</small>
							</div>
						</div>
					</b-col>					
				</b-row>
			</b-container>

			<template #modal-footer>
				<b-row>
					<b-col cols="12">
						<b-button variant="primary" type="submit" size="sm" class="float-right" @click="saveResetPassword()">
							<i class="fas fa-save"></i> Save New Password
						</b-button>
						<b-button variant="dark" type="button" size="sm" class="float-right mr-2" @click="generateRandomPass()">
							<i class="fas fa-sync"></i> Auto-generate Password
						</b-button>
					</b-col>
				</b-row>
			</template>
		</b-modal>
	</div>
</template>
<script>
	export default {
		name: 'employer-admin-users',
		props: ['AdminUsers', 'recordID'],
		data() {
			return {
				users: [],
				adminuser: {
					'firstname': null,
					'lastname': null,
					'nice_name': null,
					'email': null,
					'password': null,
					'confPassword': null,
				},
				customErrorMsg: null,
				passwordInputType: 'password',
				confPasswordInputType: 'password',
				newPass: null,
				confPass: null,
				upload_path: '/images/uploads/',
				errorChecker: [],
				is_show_error: false,
				uploadfilepath: null,
				addUserModalShow: false,
				resetPasswordModalShow: false,
				selectedAdminUser: []
			}
		},
		computed: {
			currentUser() {
				return this.$store.getters.currentUser;
			},
			isConfirmMatch() {
				if(this.adminuser.password==this.adminuser.confPassword) {
					return false;
				}
				return true;
			},
			isResetConfirmMatch() {
				if(this.newPass==this.confPass) {
					return false;
				}
				return true;
			}
		},
		created() {
			this.users = this.AdminUsers;
		},
		methods: {
			generateRandomPass() {
				this.newPass = Math.random().toString(36).substring(5);
			},
			async changeStatus(selectedUser) {

				const swalWithBootstrapButtons = this.$swal.mixin({
					customClass: {
						confirmButton: 'btn btn-success',
						cancelButton: 'btn btn-danger'
					},
					buttonsStyling: false
				})

				let user = [ selectedUser, this.currentUser.id ];

				await swalWithBootstrapButtons.fire({
					title: 'Are you sure?',
					text: selectedUser.status==1 ? "Do you want deactivate this user?" : "Do you want activate this user?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes, confirm!',
					cancelButtonText: 'No, cancel!',
					reverseButtons: true
				})
				.then((result) => {
					if (result.isConfirmed) {

						axios.post('/api/user/update_status', {
							headers: { "Authorization": `Bearer ${this.currentUser.token}`},
							user
						})
						.then((response) => {
							if(selectedUser.status==1) {
								swalWithBootstrapButtons.fire('Deactivated!','User successfully deactivated.','success');
							} 
							else {
								swalWithBootstrapButtons.fire('Activated!','User successfully activated.','success');
							}
							this.$set(selectedUser, 'status', response.data.status);
						})
						.catch((error) => {
							console.log(error);
						})



					} 
				})
			},
			saveResetPassword() {

				let newData = {
					'loggedinUser': this.currentUser.id,
					'user_id': this.selectedAdminUser.id,
					'newpass': this.newPass,
					'confPass': this.confPass,
				};

				this.$validator.validateAll()
					.then((result) => {
						if(!this.errors.any()) {
							axios.post('/api/user/reset_password', {headers: { "Authorization": `Bearer ${this.currentUser.token}`}, newData})
								.then((response) => {
									if(response.data.errors) {
										this.is_show_error = true;
										this.errorChecker = response.data.errors;
									}
									else {
										if(response.data.status) {
											this.$swal.fire({
												position: 'top-end',
												icon: 'success',
												title: 'Password changed successfully',
												showConfirmButton: false,
												timer: 1500
											});
										} else {
											console.log(response.data.status);
										}

										this.newPass = null;
										this.confPass = null;
										this.resetPasswordModalShow = false;

									}
								})
								.catch((error) => {
									console.log(error);
								})
						} 
						else {
							this.is_show_error = true;
							this.errorChecker = this.errors.all();
						}
					})
					.catch((errors) => {
						console.log(errors);
					});
			},
			resetPasswordForm(selectedUser) {
				this.selectedAdminUser = selectedUser;
				this.resetPasswordModalShow = !this.resetPasswordModalShow;
				this.newPass = '';
				this.confPass = '';
			},
			resetUserForm() {
				this.addUserModalShow = !this.addUserModalShow;
				this.uploadfilepath = null;
				this.title = '';
				this.overview = '';
				this.pdf_file = {};
				this.isFileAttached = false;
			},

            createNewUser() {
            	this.is_show_error = false;

            	this.$validator.validateAll()
					.then((result) => {
						if(!this.errors.any()) {
							if(this.adminuser.password !== this.adminuser.confPassword) {
								this.is_show_error = true;
								this.customErrorMsg = "Password & confirm password did not match";
								return false
							}
							let userdata = {
								'user_id': this.currentUser.id,
								'employer_id': this.$route.params.id,
								'firstname': this.adminuser.firstname,
								'lastname': this.adminuser.lastname,
								'nice_name': this.adminuser.nice_name,
								'email': this.adminuser.email,
								'password': this.adminuser.password,
								'confPassword': this.adminuser.confPassword,
							};

            				axios.post('/api/user/add', {headers: { "Authorization": `Bearer ${this.currentUser.token}`}, userdata})
								.then((response) => {

									if(response.data.emailExist) {
										this.is_show_error = true;
										this.customErrorMsg = 'Oops! Something is wrong. '+response.data.emailExist;
										return false;
									}

									if(response.data.errors) {
										this.is_show_error = true;
										this.errorChecker = response.data.errors;
									}
									else {
										this.$swal.fire({
											position: 'top-end',
											icon: 'success',
											title: 'New user added successfully',
											showConfirmButton: false,
											timer: 1500
										});
										this.addUserModalShow = false;
										this.adminuser = [];
										this.AdminUsers.push(response.data.user);
									}
								})
								.catch((error) => {
									console.log(error);
								})
						} 
						else {
							this.is_show_error = true;
							this.errorChecker = this.errors.all();
						}
					})
					.catch((errors) => {
						console.log(errors);
					});
            }
		}
	}
</script>
<style scoped>
	.custom-size {
		font-size: 20px !important;
	}
	.photo_img {
		object-fit: cover;
		width: 32px;
		height: 32px;
	}

	.resumeStyle {
		object-fit: cover;
		width: 50px;
		height: 50px;
	}

	input[type="file"] {
	    display: none;
	}

	.custom-file-upload {
	    border: 1px solid #ccc;
	    display: inline-block;
	    padding: 6px 12px;
	    cursor: pointer;
	}

	.isAttached {
		border:1px solid red;
	}
</style>