import { getLocalUser } from "./helpers/auth";

const user = getLocalUser();

export default {
	state: {
		currentUser: user,
		isLoggedIn: !!user,
		loading: false,
		auth_error: null,
		applicants: [],
		applicant: null,
		jobs: [],
		sectors: [],
		jobclass: [],
		jobsubclass: [],
		// ======
		applications: [],
		application: null,
		logs: [],
	},
	getters: {

		isLoading(state) {
			return state.loading;
		},
		isLoggedIn(state) {
			return state.isLoggedIn;
		},
		currentUser(state) {
			return state.currentUser;
		},
		authError(state) {
			return state.auth_error;
		},
		applicants(state) {
			return state.applicants;
		},
		applicant(state) {
			return state.applicant;
		},
		sectors(state) {
			return state.sectors;
		},
		jobs(state) {
			return state.jobs;
		},
		jobclass(state) {
			return state.jobclass;
		},
		jobsubclass(state) {
			return state.jobsubclass;
		},
		applications(state) {
			return state.applications;
		},
		application(state) {
			return state.application;
		},
		logs(state) {
			return state.logs;
		},
	},
	mutations: {

		LOGIN(state) {
			state.loading = true;
			state.auth_error = null;
		},

		LOGIN_SUCCESS(state, payload) {
			state.auth_error = null;
			state.loading = false;
			state.isLoggedIn = true;
			state.currentUser = Object.assign({}, payload.user, {token: payload.access_token});
			localStorage.setItem("user", JSON.stringify(state.currentUser));
		},

		LOGIN_FAILED(state, payload) {
			state.loading = false;
			state.auth_error = payload.error;
		},

		LOGOUT(state) {
			localStorage.removeItem('user');
			state.isLoggedIn = false;
			state.currentUser = null;
		},

		FETCH_APPLICANTS(state, payload) {
			state.applicants = payload;
		},

		FETCH_SECTORS(state, payload) {
			state.sectors = payload;
		},

		FETCH_JOBS(state, payload) {
			state.jobs = payload;
		},

		ADD_SECTOR(state, payload) {
            state.sectors.push(payload);
		},

		FETCH_JOBCLASS(state, payload) {
			state.jobclass = payload;
		},

		FETCH_JOBSUBCLASS(state, payload) {
			state.jobsubclass = payload;
		},

		SET_APPLICATIONS(state, payload) {
			state.applications = payload;
		},
		GET_APPLICATIONS(state, payload) {
			state.application = payload;
		},
		FETCH_LOGS(state, payload) {
			state.logs = payload;
		},
	},
	actions: {

		login(context) {
			context.commit('LOGIN');
		},

		logout(context) {
			context.commit('LOGOUT');
		},
		

		fetchSectors(context) {
			axios.get('/api/industry', {
				headers: { "Authorization": `Bearer ${context.state.currentUser.token}`}
			})
			.then((response) => {
				context.commit('FETCH_SECTORS', response.data.industries);
			})
			.catch((error) => {
				console.log(error);
			})
		},

		async fetchMyJobs(context) {
			let employer_id = context.state.currentUser.employer_id;
			await axios.get('/api/jobs/'+employer_id, {
					headers: { "Authorization": `Bearer ${context.state.currentUser.token}`}
				})
				.then((response) => {
					context.commit('FETCH_JOBS', response.data.jobs);
				})
				.catch((error) => {
					console.log(error);
				})
		},
		
		fetchJobClass(context) {
			axios.get('/api/jobclassification', {
				headers: { "Authorization": `Bearer ${context.state.currentUser.token}`}
			})
			.then((response) => {
				context.commit('FETCH_JOBCLASS', response.data.jobclass);
			})
			.catch((error) => {
				console.log(error);
			})
		},

		fetchJobSubClass(context) {
			axios.get('/api/jobsubclassification', {
				headers: { "Authorization": `Bearer ${context.state.currentUser.token}`}
			})
			.then((response) => {
				context.commit('FETCH_JOBSUBCLASS', response.data.jobsubclass);
			})
			.catch((error) => {
				console.log(error);
			})
		},

		async fetchApplications(context, employer_id) {
			try {
				const response = await axios.get('/api/applications/'+employer_id, {
									headers: { "Authorization": `Bearer ${context.state.currentUser.token}`}
								});
				context.commit('SET_APPLICATIONS', response.data.applications);

			} 
			catch(error) {
				throw error;
			}
		},

		async getApplication(context, application_id) {
			try {
				const response = await axios.get('/api/get_application/'+application_id, {
									headers: { "Authorization": `Bearer ${context.state.currentUser.token}`}
								});
				context.commit('GET_APPLICATIONS', response.data.application);
			} 
			catch(error) {
				throw error;
			}
		},

		async fetchMyApplicants(context) {
			let employer_id = context.state.currentUser.employer_id;
			try {
				const response = await axios.get('/api/my_applications/'+employer_id, {
									headers: { "Authorization": `Bearer ${context.state.currentUser.token}`}
								});
				context.commit('FETCH_APPLICANTS', response.data.applicants);
			} 
			catch(error) {
				throw error;
			}
			
		},

		async fetchMyLogs(context, employer_id) {
			try {
				const response = await axios.get('/api/dashboard/logs/'+employer_id, {
									headers: { "Authorization": `Bearer ${context.state.currentUser.token}`}
								});
				context.commit('FETCH_LOGS', response.data.logs);
			} 
			catch(error) {
				throw error;
			}
		},

	}
}
