<template>
	<div>
		<div id="main" class="wrapper">
			<top-navigation />
			<sidebar />
			<router-view></router-view>
		</div>
	</div>
</template>

<script>

	import TopNavigation from '../../layout/TopNavigation.vue'
	import Sidebar from '../../layout/Sidebar.vue'

	export default {
		name: 'user-main',
		components: {
			TopNavigation,
			Sidebar
		},
	}

</script>