<template>
	<div>
		<div class="row">
			<div class="col-md-2">
				<img :src="applicantProfileImg==null ? '/images/default_user.png' : applicantProfileImg" class="img-fluid" />
				<hr />
				<h5>Interests:</h5>
				<ul v-if="applicantInfo.interests" class="list-group">
					<li class="list-group-item" v-for="interest in applicantInfo.interests">{{interest.name}}</li>
				</ul>
				<p v-else class="text-muted text-center">No interests found</p>
			</div>
			<div class="col-md-7">
				<div class="form-group row">
					<label class="col-md-2 col-form-label">First Name</label>
					<div class="col-md-10">
						<div class="input-group">
							<input v-model="applicantFirstName" type="text" class="form-control" disabled>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-md-2 col-form-label">Last Name</label>
					<div class="col-md-10">
						<div class="input-group">
							<input v-model="applicantLastName" type="text" class="form-control" disabled>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-md-2 col-form-label">Phone No.</label>
					<div class="col-md-10">
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text"><i class="fas fa-phone"></i></span>
							</div>
							<input v-model="applicantPhone" type="text" class="form-control" disabled>
						</div>
					</div>
				</div>
				<div class="form-group row mb-4 pb-1">
					<label class="col-md-2 col-form-label">Email Address</label>
					<div class="col-md-10">
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text"><i class="fas fa-envelope"></i></span>
							</div>
							<input v-model="applicantEmail" type="text" class="form-control" disabled>
						</div>
					</div>
					<br />
				</div>	
			</div>
		</div>
	</div>
</template>
<script>

	import {Stretch} from 'vue-loading-spinner'
	import UploadPhoto from '../utilities/UploadPhoto'
	import ApplicationHistory from '../applicants/ApplicationHistory'
	import ApplicantResume from '../applicants/ApplicantResume'
	import ApplicantInterests from '../applicants/ApplicantInterests'
	import Multiselect from 'vue-multiselect'

	export default {
		name: 'view-applicant',
		components: {
			Stretch,
			UploadPhoto,
			Multiselect,
			ApplicationHistory,
			ApplicantResume,
			ApplicantInterests
		},
		data() {
			return {
				applicantInterests: null,
				applicantInfo: [],
				applicant_id: null,
				uploadPath: '/images/uploads/',
				applicantProfileImg: null,
				applicantFirstName: null,
				applicantLastName: null,
				applicantEmail: null,
				applicantPhone: null,
			}
		},
		computed: {
			currentUser() {
				return this.$store.getters.currentUser;
			}
		},
		created() {
			this.preLoadApplicant();
		},
		methods: {
			preLoadApplicant() {
				axios.get(`/api/applicants/${this.$route.params.id}`, {
					headers: { "Authorization": `Bearer ${this.currentUser.token}`}
				})
				.then((response) => {
					if(!response.data.applicant) {
						this.$swal.fire({
							position: 'center',
							icon: 'error',
							title: 'Oops..PAGE NOT FOUND',
							timer: 1500
						});
						setTimeout(() => this.$router.push('/404'), 1500);
					} 
					this.applicantInfo = response.data.applicant;
					this.applicant_id = response.data.applicant.id;
					this.applicantFirstName = response.data.applicant.firstname;
					this.applicantLastName = response.data.applicant.lastname;
					this.applicantEmail = response.data.applicant.email;
					this.applicantPhone = response.data.applicant.phone;
					this.applicantProfileImg = response.data.applicant.profile_img;
					this.applicantResume = response.data.applicant.resumes

				})
				.catch((error) => {
					console.log(error);
				})
			},
			updateRecord(columnName, newData) {
				let updatedData = {
					applicant_id: this.applicant_id,
					columnName : columnName,
					newData : newData
				}
				axios.post('/api/applicants/update', updatedData, {
					headers: { "Authorization": `Bearer ${this.currentUser.token}`},
				})
				.then((response) => {

					if(response.data.error) {
						this.$swal.fire({
						  icon: 'error',
						  title: 'Oops...',
						  text: response.data.error,
						});
					} 
					else {
						this.$swal.fire({
							position: 'top-end',
							icon: 'success',
							title: 'Record updated successfully',
							showConfirmButton: false,
							timer: 1500
						});
					}

					if(columnName == "title") {
						setTimeout(() => this.$router.push('/job/'+response.data.job.slug), 1500);
					}
				})
				.catch((error) => {
					console.log(error);
				})
			},
		}
		
	}
</script>
<style scoped>
	.photo_img {
		object-fit: cover;
		width: inherit;
	}
</style>