<template>
	<div>
		<img v-if="photo==null" :src="uploadfilepath.length ? uploadfilepath : '/images/default_user.png'" class="profile_photo" />
		<img v-else :src="uploadfilepath.length ? uploadfilepath : photo" class="profile_photo" />
		<form role="form" @submit.prevent="changePhoto" enctype="multipart/form-data">
			<div class="input-group mt-2">
				<div v-if="!isShowUploadBtn" class="custom-file">
					<input @change="upload_photo()" accept="image/*" type="file" class="form-control form-control-sm" id="exampleInputFile">
					<label class="custom-file-label" for="exampleInputFile"><small>Change Photo</small></label>
				</div>
				<div v-if="isShowUploadBtn" class="input-group-append" style="cursor: pointer !important;">
					<span class="input-group-text bg-danger mr-1" @click="isShowUploadBtn=false; uploadfilepath=''" >
						<i class="fas fa-times"></i>&nbsp; Cancel</span>
					<button type="submit" class="input-group-text bg-dark"><i class="fas fa-upload"></i>&nbsp; Upload</button>
				</div>
			</div>
		</form>
	</div>

</template>

<script>
	
	export default {
		name: 'upload-photo',
		props: ['recordType', 'recordID', 'photo'],
		data() {
			return {
				uploadPath: '/images/uploads/',
				photo_img: '',
				isShowUploadBtn: false,
				uploadfilepath: '',
			}
		},
		computed: {
			currentUser() {
				return this.$store.getters.currentUser;
			},
		},
		created() {
			// console.log(this.recordID);
		},

		methods: {

			changePhoto() {
			    let apiUrl = null;
				const formData = new FormData();
				const config = {"headers": 
									{ 'content-type': 'multipart/form-data', "Authorization": "Bearer "+this.currentUser.token }	
			                	}

			    if(this.recordType=='employerRecord') {
			    	apiUrl = '/api/employer/upload_photo';
					formData.append('photo_img', this.photo_img);
					formData.append('old_photo_name', this.photo);
					formData.append('employer_id', this.recordID);
					formData.append('user_id', this.currentUser.id);

			    }
			    	
			    else if(this.recordType=='jobRecord') {
			    	apiUrl = '/api/job/upload_photo';
			    	formData.append('job_img', this.photo_img);
					formData.append('old_photo_name', this.photo);
					formData.append('user_id', this.recordID);
					formData.append('job_id', this.currentUser.id);
			    } 

			    else if(this.recordType=='applicantRecord') {
			    	apiUrl = '/api/applicants/upload_photo';
					formData.append('photo_img', this.photo_img);
					formData.append('old_photo_name', this.photo);
					formData.append('user_id', this.recordID);
					formData.append('applicant_id', this.currentUser.id);
			    }

			    else if(this.recordType=='userRecord') {
			    	apiUrl = '/api/user/upload_photo';
					formData.append('photo_img', this.photo_img);
					formData.append('old_photo_name', this.photo);
					formData.append('user_id', this.recordID);
			    }

				axios.post(apiUrl, formData, config)
					.then((response) => {
						this.$swal.fire({
							position: 'top-end',
							icon: 'success',
							title: 'Photo uploaded successfully',
							showConfirmButton: false,
							timer: 1500
						});
						this.isShowUploadBtn = false;
					})
					.catch((error) => {
						console.log(error);
					})

			},
            upload_photo(e) {
            	this.isShowUploadBtn = true;
                this.photo_img = event.target.files[0];
                this.uploadfilepath = window.URL.createObjectURL(event.target.files[0]);
            },

		}
	}

</script>

<style scoped>
	.profile_photo {
		width: 100%;
		object-fit: cover;
		border:2px solid #ddd;
	}
</style>