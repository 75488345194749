<template>
    <div>
        <div class="container-fluid">
            <div class="row justify-content-center mt-4">
                <div class="col-md-10">
                    <div class="site_title clearfix text-center" style="padding: 60px;">
                        <img src="/images/logo.png" class="img_logo pr-2" style="width: 75%;">
                    </div>
                </div>
            </div>

            <!-- navigation  -->
            <div class="row justify-content-center nav_bg">
                <div class="col-md-10">
                    <Navigation></Navigation>
                </div>
            </div>
            <div class="row justify-content-center" style="min-height: 400px !important;">
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-12 custom-margin">
                            <h4>Ask how we can help you:</h4>
                            <br />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h5>For email support:</h5>
                            <p class="text-muted mb-0">Email: traineeship@streamsoflife.sg</p>
                            <br />
                            <br />
                            <h5>Office Location:</h5>
                            <p class="text-muted mb-0">7 Armenian St., Singapore 179932</p>
                            <br />
                            <br />
                            <h5>Direct Line:</h5>
                            <p class="text-muted mb-0"><i class="fas fa-phone"></i> Phone : +65 6337 3222</p>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <p class="mb-0"><small>Email Address</small></p>
                                <input :class="{ 'is-invalid' : errors.first('emailAdd') }" 
                                    v-model="contactUs.emailAdd" 
                                    type="email" 
                                    name="emailAdd" 
                                    class="form-control" 
                                    v-validate="'required|email'">
                                <small class="text-danger">{{ errors.first('emailAdd') }}</small>
                            </div>
                            <div class="form-group">
                                <p class="mb-0"><small>First Name</small></p>
                                <input :class="{ 'is-invalid' : errors.first('fname') }" 
                                    v-model="contactUs.fname" 
                                    type="text" 
                                    name="fname" 
                                    class="form-control" 
                                    v-validate="'required'">
                                <small class="text-danger">{{ errors.first('fname') }}</small>
                            </div>
                            <div class="form-group">
                                <p class="mb-0"><small>Last Name</small></p>
                                <input :class="{ 'is-invalid' : errors.first('lname') }" 
                                    v-model="contactUs.lname" 
                                    type="text" 
                                    name="lname" 
                                    class="form-control" 
                                    v-validate="'required'">
                                <small class="text-danger">{{ errors.first('lname') }}</small>
                            </div>
                            <div class="form-group">
                                <p class="mb-0"><small>Message</small></p>
                                <textarea class="form-control" 
                                    row="4" v-model="contactUs.message" 
                                    name="message" 
                                    :class="{ 'is-invalid' : errors.first('message') }"
                                    v-validate="'required'">
                                </textarea>
                                <small class="text-danger">{{ errors.first('message') }}</small>
                            </div>
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                <label class="form-check-label" for="exampleCheck1"><small>I agree and understand to the <a href="/privacy-policy">Privacy Policy</a></small></label>
                            </div>
                            <div class="form-group mt-4">
                                <button class="btn btn-danger d-block">SEND</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- footer -->
            <pageFooter></pageFooter>
        </div>
    </div>
</template>
<script>
    import Navigation from '../../layout/Navigation';
    import pageFooter from '../../layout/Footer';

    import moment from 'moment';

    export default {
        name: "contact-us",
        components: {
            Navigation,
            pageFooter
        },
        data() {
            return {
                newsTitle: null,
                contactUs: {
                    emailAdd: null,
                    fname: null,
                    lname: null,
                    message: null,
                }
            }
        },
        created() {
          this.newsTitle = "Contact Us";
        },
        methods: {
            dateFormatter(data) {
                return moment(data).format("MMM DD, YYYY");
            },
            dateCounter(data) {
                return moment(data,"YYYYMMDD").fromNow();
            }
        }

    }

</script>
<style scoped>
    .custom-margin {
        padding: 25px 15px 15px 15px;
    }
</style>