import Home from './components/Home.vue';
import Dashboard from './components/users/Dashboard.vue';
import Login from './components/auth/Login.vue';

import ApplicantsMain from './components/applicants/Main.vue';
import ApplicantsList from './components/applicants/List.vue';
import Applicant from './components/applicants/View.vue';

import Application from './components/jobs/Application.vue';

import EmployersMain from './components/employers/Main.vue';
import Employer from './components/employers/View.vue';
import EmployerJobNew from './components/jobs/EmployerJobNew.vue';
import PageNotFound from './components/utilities/PageNotFound.vue';

import JobMain from './components/jobs/JobMain.vue';
import JobList from './components/jobs/List.vue';
import Job from './components/jobs/View.vue';

import UserMain from './components/users/Main.vue';
import User from './components/users/View.vue';
import OrganizationProfiles from './components/pages/org_profile.vue';
import TraineeshipProgrammes from './components/pages/traineeship_programmes.vue';
import SingleJob from './components/pages/Single_Job.vue';
import NewsEvents from './components/pages/news_events.vue';
import SingleNewsEvents from './components/pages/singleNewsEvents.vue';
import ContactUs from './components/pages/contactUs.vue';
import PrivacyPolicy from './components/pages/PrivacyPolicy.vue';

export const routes = [
	{
		path: '/',
		component: Home,
	},
	{
		path: '/dashboard',
		component: Dashboard,
		meta: { requiresAuth: true }
	},
	{
		path: '/login',
		component: Login
	},
	{
		path: '/user',
		component: UserMain,
		meta: { requiresAuth: true },
		children: [
			{
				path: ':id',
				component: User
			},
		]
	},
	{
		path: '/application/:id',
		component: Application,
		meta: { requiresAuth: true }
	},
	{
		path: '/applicant',
		alias: '/applicants',
		component: ApplicantsMain,
		meta: { requiresAuth: true },
		children: [
			{
				path: '/',
				component: ApplicantsList
			},
			{
				path: ':id',
				component: Applicant
			},
		]
	},
	{
		path: '/job',
		alias: '/jobs',
		component: JobMain,
		meta: { requiresAuth: true },
		children: [
			{
				path: '/',
				component: JobList
			},
			{
				path: ':slug',
				component: Job
			},

		]
	},
	{
		path: '/employer',
		alias: '/employers',
		component: EmployersMain,
		meta: { requiresAuth: true },
		children: [
			{
				path: ':id',
				component: Employer
			},
			{
				path: ':id/job/new',
				component: EmployerJobNew
			},
		]
	},
	{
		path: '*',
		component: PageNotFound,
	},
	{
		path: '/404',
		component: PageNotFound,
	},
	{
		path: '/organization-profiles',
		component: OrganizationProfiles,
	},
	{
		path: '/traineeship-programmes',
		component: TraineeshipProgrammes,
	},
	{
		path: '/apply/:slug',
		component: SingleJob,
	},
	{
		path: '/news-events',
		component: NewsEvents,
	},
	{
		path: '/:type/:slug',
		component: SingleNewsEvents,
	},
	{
		path: '/contact-us',
		component: ContactUs,
	},
	{
		path: '/privacy-policy',
		component: PrivacyPolicy,
	},
];