<template>
	<div>
		<div class="card card-default">
			<div class="card-header">
				<h5 class="float-left font-weight-bold mt-2">Job Information</h5>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-3" style="border-right: 1px solid #ddd;">
						<!-- <img :src="theJob.job_img" class="photo_img"> -->
						<UploadPhoto :recordType="'jobRecord'" :recordID="theJob.id" :photo="theJob.job_img"></UploadPhoto>
						<hr />
						<UpdateJobClassification :job_class="theJob"></UpdateJobClassification>
					</div>
					<div class="col-md-9">
						<form class="form-horizontal" role="form">
							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Job Title</label>
								<div class="col-md-10">
									<div class="input-group">
										<input 
											v-model="jobTitle" 
											type="text" 
											class="form-control" 
											:class="{ 'is-invalid' : errors.first('job_itle') }" 
											name="job_itle" 
											v-validate="'required'"
											:disabled="isJobTitleDisabled">
										<span class="input-group-append">
											<button @click="isJobTitleDisabled ? isJobTitleDisabled=false : isJobTitleDisabled=true" type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isJobTitleDisabled, 'btn-dark':isJobTitleDisabled}">
												<i v-if="isJobTitleDisabled" class="fas fa-lock"></i>
												<i v-else class="fas fa-lock-open"></i>
											</button>
											<button @click='updateRecord("title",jobTitle)' :disabled="isJobTitleDisabled" type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
										</span>
									</div>
									<small class="text-danger">{{ errors.first('job_itle') }}</small>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">JobDescription</label>
								<div class="col-md-10">
									<wysiwyg v-model="description" />
									<div class="clearfix">
										<button @click='updateRecord("description",description)' type="button" class="btn btn-success btn-flat text-white float-right mt-1"><i class="fas fa-save"></i></button>
									</div>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Job Responsibilities</label>
								<div class="col-md-10">
									<wysiwyg v-model="jobResponsibilities" />
									<div class="clearfix">
										<button @click='updateRecord("responsibilities",jobResponsibilities)' type="button" class="btn btn-success btn-flat text-white float-right mt-1"><i class="fas fa-save"></i></button>
									</div>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Qualifications</label>
								<div class="col-md-10">
									<wysiwyg v-model="qualifications" />
									
									<div class="clearfix">
										<button @click='updateRecord("qualifications",qualifications)' type="button" class="btn btn-success btn-flat text-white float-right mt-1"><i class="fas fa-save"></i></button>
									</div>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Carrer Level</label>
								<div class="col-md-10">
									<div class="input-group">
										<select v-model="theJob.career_level" class="custom-select" @change="isCareerLevelDisabled=false">
											<option value="Not Applicable">Not Applicable</option>
											<option value="Entry-level">Entry-level</option>
											<option value="Intermediate">Intermediate</option>
											<option value="Mid-level">Mid-level</option>
											<option value="Senior or executive-level">Senior or executive-level</option>
										</select>
										<span class="input-group-append">
											<button :disabled="isCareerLevelDisabled" @click='updateRecord("career_level",theJob.career_level)' type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
										</span>
									</div>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Years Experience</label>
								<div class="col-md-10">
									<div class="input-group">
										<select v-model="theJob.yrs_exp" class="custom-select" @change="isYrsExpDisabled=false">
											<option value="1-2 Years Experience">1-2 Years Experience</option>
											<option value="2-4 Years Experience">2-4 Years Experience</option>
											<option value="4-10 Years Experience">4-10 Years Experience</option>
											<option value="10+ years Experience">10+ years Experience</option>
											<option value="Senior or executive-level">Senior or executive-level</option>
										</select>
										<span class="input-group-append">
											<button :disabled="isYrsExpDisabled" @click='updateRecord("yrs_exp",theJob.yrs_exp)' type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
										</span>
									</div>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Job Type</label>
								<div class="col-md-10">
									<div class="input-group">
										<select v-model="theJob.job_type" class="custom-select" @change="isJobTypeDisabled=false">
											<option value="Full-time">Full-time</option>
											<option value="Part-time">Part-time</option>
											<option value="Volunteer">Volunteer</option>
											<option value="Trainee">Trainee</option>
											<option value="Internship">Internship</option>
										</select>
										<span class="input-group-append">
											<button :disabled="isJobTypeDisabled" @click='updateRecord("job_type",theJob.job_type)' type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
										</span>
									</div>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Salary Offer</label>
								<div class="col-md-10">
									<div class="input-group">
										<input 
											v-model="salary" 
											type="text" 
											class="form-control" 
											:class="{ 'is-invalid' : errors.first('salary') }" 
											name="salary" 
											v-validate="'required'"
											:disabled="isSalaryDisabled">
										<span class="input-group-append">
											<button @click="isSalaryDisabled ? isSalaryDisabled=false : isSalaryDisabled=true" type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isSalaryDisabled, 'btn-dark':isSalaryDisabled}">
												<i v-if="isSalaryDisabled" class="fas fa-lock"></i>
												<i v-else class="fas fa-lock-open"></i>
											</button>
											<button @click='updateRecord("salary",salary)' :disabled="isSalaryDisabled" type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
										</span>
									</div>
									<small class="text-danger">{{ errors.first('salary') }}</small>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Job Location</label>
								<div class="col-md-10">
									<div class="input-group">
										<input 
											v-model="job_loc" 
											type="text" 
											class="form-control" 
											:class="{ 'is-invalid' : errors.first('location') }" 
											name="location" 
											v-validate="'required'"
											:disabled="isJobLocationDisabled">
										<span class="input-group-append">
											<button @click="isJobLocationDisabled ? isJobLocationDisabled=false : isJobLocationDisabled=true" type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isJobLocationDisabled, 'btn-dark':isJobLocationDisabled}">
												<i v-if="isJobLocationDisabled" class="fas fa-lock"></i>
												<i v-else class="fas fa-lock-open"></i>
											</button>
											<button @click='updateRecord("location",job_loc)' :disabled="isJobLocationDisabled" type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
										</span>
									</div>
									<small class="text-danger">{{ errors.first('location') }}</small>
								</div>
							</div>

							<div class="form-group row mt-4 py-4">
								<div class="col-md-12">
									<hr class="" />
									<h5>Additional Information</h5>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Where to submit application</label>
								<div class="col-md-10">
									<div class="input-group">
										<input 
											v-model="emailToSubmitResume" 
											type="text" 
											class="form-control" 
											:class="{ 'is-invalid' : errors.first('emailToSubmitResume') }" 
											name="emailToSubmitResume" 
											v-validate="'required'"
											:disabled="isemailToSubmitResumeDisabled">
										<span class="input-group-append">
											<button @click="isemailToSubmitResumeDisabled ? isemailToSubmitResumeDisabled=false : isemailToSubmitResumeDisabled=true" type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isemailToSubmitResumeDisabled, 'btn-dark':isemailToSubmitResumeDisabled}">
												<i v-if="isemailToSubmitResumeDisabled" class="fas fa-lock"></i>
												<i v-else class="fas fa-lock-open"></i>
											</button>
											<button @click='updateRecord("emailToSubmitResume",emailToSubmitResume)' :disabled="isemailToSubmitResumeDisabled" type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
										</span>
									</div>
									<small class="text-danger">{{ errors.first('emailToSubmitResume') }}</small>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Schedule Post</label>
								<div class="col-md-10">
									<div class="input-group">
										<input 
											v-model="schedule_post" 
											type="date" 
											class="form-control" 
											:class="{ 'is-invalid' : errors.first('schedule_post') }" 
											name="schedule_post" 
											v-validate=""
											:disabled="isSchedulePostDisabled">
										<span class="input-group-append">
											<button @click="isSchedulePostDisabled ? isSchedulePostDisabled=false : isSchedulePostDisabled=true" type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isSchedulePostDisabled, 'btn-dark':isSchedulePostDisabled}">
												<i v-if="isSchedulePostDisabled" class="fas fa-lock"></i>
												<i v-else class="fas fa-lock-open"></i>
											</button>
											<button @click='updateRecord("schedule_post",schedule_post)' :disabled="isSchedulePostDisabled" type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
										</span>
									</div>
									<small class="text-danger">{{ errors.first('schedule_post') }}</small>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Post Validity</label>
								<div class="col-md-10">
									<div class="input-group">
										<input 
											v-model="valid_until" 
											type="date" 
											class="form-control" 
											:class="{ 'is-invalid' : errors.first('validity') }" 
											name="validity" 
											v-validate=""
											:disabled="isValidityDisabled">
										<span class="input-group-append">
											<button @click="isValidityDisabled ? isValidityDisabled=false : isValidityDisabled=true" type="button" class="btn btn-flat text-white mr-1" :class="{'btn-info':!isValidityDisabled, 'btn-dark':isValidityDisabled}">
												<i v-if="isValidityDisabled" class="fas fa-lock"></i>
												<i v-else class="fas fa-lock-open"></i>
											</button>
											<button @click='updateRecord("valid_until",valid_until)' :disabled="isValidityDisabled" type="button" class="btn btn-success btn-flat text-white"><i class="fas fa-save"></i></button>
										</span>
									</div>
									<small class="text-danger">{{ errors.first('validity') }}</small>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Status</label>
								<div class="col-md-10">
									<div class="btn-group btn-group-toggle" data-toggle="buttons">
										<label class="btn btn-secondary" :class="{'btn-success':setStatus==1}">
											<input @change="changeStatus('status', setStatus)" 
											:checked="setStatus==1" 
											v-model="setStatus" 
											value="1" type="radio"> APPROVE
										</label>
										<label class="btn btn-secondary" :class="{'btn-danger':setStatus==0}">
											<input @change="changeStatus('status', setStatus)" 
											:checked="setStatus==0" 
											v-model="setStatus" value="0" type="radio"> CLOSE
										</label>
									</div>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Searcheable</label>
								<div class="col-md-10">
									<div class="btn-group btn-group-toggle" data-toggle="buttons">
										<label class="btn btn-secondary" :class="{'btn-success':is_searcheable==1}">
											<input @change="changeStatus('is_searcheable', is_searcheable)" 
											:checked="is_searcheable==1" 
											v-model="is_searcheable" 
											value="1" type="radio"> YES
										</label>
										<label class="btn btn-secondary" :class="{'btn-danger':is_searcheable==0}">
											<input @change="changeStatus('is_searcheable', is_searcheable)" 
											:checked="is_searcheable==0" 
											v-model="is_searcheable" 
											value="0" type="radio"> NO
										</label>
									</div>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-md-2 col-form-label text-uppercase">Date Posted</label>
								<div class="col-md-10">
									<h5 class="mb-0 mt-1 text-muted font-italic">{{ datePosted }}</h5>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	import CircleSpin from 'vue-loading-spinner/src/components/Circle'
	import UpdateJobClassification from '../jobs/UpdateJobClassification'
	import UploadPhoto from '../utilities/UploadPhoto'
	import moment from 'moment'
	
	export default {
		name: 'single-job',
		props:['theJob'],
		components: {
			CircleSpin,
			UpdateJobClassification,
			UploadPhoto
		},
		data() {
			return {
				jobTitle: null,
				description: null,
				jobResponsibilities: null,
				qualifications: null,
				salary: null,
				job_loc: null,
				emailToSubmitResume: null,
				schedule_post: null,
				valid_until: null,
				setStatus: 0,
				is_searcheable: 0,
				datePosted: null,

				isJobTitleDisabled: true,
				isDescDisabled: true,
				isJobRespDisabled: true,
				isCareerLevelDisabled: true,
				isYrsExpDisabled: true,
				isJobTypeDisabled: true,
				isQualifDisabled: true,
				isSalaryDisabled: true,
				isJobLocationDisabled: true,
				isemailToSubmitResumeDisabled: true,
				isSchedulePostDisabled: true,
				isValidityDisabled: true,
			}
		},
		computed: {
			currentUser() {
				return this.$store.getters.currentUser;
			}
		},
		created() {
			this.jobTitle = this.theJob.title;
			this.description = this.theJob.description;
			this.jobResponsibilities = this.theJob.responsibilities;
			this.qualifications = this.theJob.qualifications;
			this.salary = this.theJob.salary;
			this.job_loc = this.theJob.location;
			this.emailToSubmitResume = this.theJob.emailToSubmitResume;
			this.schedule_post = this.dateFormatter(this.theJob.schedule_post);
			this.valid_until = this.dateFormatter(this.theJob.valid_until);
			this.setStatus = this.theJob.status;
			this.is_searcheable = this.theJob.is_searcheable;
			this.datePosted = moment(this.theJob.created_at).format("MMM DD, YYYY") + 
							' ('+moment(this.theJob.created_at, "YYYYMMDD").fromNow()+')';
		},
		methods: {
			updateRecord(columnName, newData) {

				let updatedData = {
					job_id: this.theJob.id,
					user_id: this.currentUser.id,
					columnName : columnName,
					newData : newData
				}

				axios.post('/api/job/update', updatedData, {
					headers: { "Authorization": `Bearer ${this.currentUser.token}`},
				})
				.then((response) => {

					if(response.data.error) {
						this.$swal.fire({
						  icon: 'error',
						  title: 'Oops...',
						  text: response.data.error,
						});
					} 
					else {
						this.$swal.fire({
							position: 'top-end',
							icon: 'success',
							title: 'Record updated successfully',
							showConfirmButton: false,
							timer: 1500
						});
					}

					if(columnName == "title") {
						setTimeout(() => this.$router.push('/job/'+response.data.job.slug), 1500);
					}
				})
				.catch((error) => {
					console.log(error);
				})
			},

			dateFormatter(data) {
				if(data==null) return null;
				const checkData = (data.indexOf(' ') <= -1 || data!='null') ? data : data.split(/(\s+)/)[0];
				return moment(checkData).format("YYYY-MM-DD");
			},
			changeStatus(columnName, val) {
				let updatedData = {
					job_id: this.theJob.id,
					user_id: this.currentUser.id,
					columnName: columnName,
					newData: val
				}

				axios.post('/api/job/update', updatedData, {
					headers: { "Authorization": `Bearer ${this.currentUser.token}`},
				})
				.then((response) => {

					if(response.data.error) {
						this.$swal.fire({
						  icon: 'error',
						  title: 'Oops...',
						  text: response.data.error,
						});
					} 
					else {
						this.$swal.fire({
							position: 'top-end',
							icon: 'success',
							title: 'Record updated successfully',
							showConfirmButton: false,
							timer: 1500
						});
					}
				})
				.catch((error) => {
					console.log(error);
				})
			}
		}
		
	}

</script>
<style scoped>
	.photo_img {
		object-fit: cover;
		width: inherit;
	}
</style>