<template>
	<div>
		<div class="clearfix">
			<h6 class="font-weight-bold float-left">My interests</h6>
			<button class="btn btn-link btn-sm float-right p-0" @click="is_addInterest"><i class="fas fa-plus"></i> add</button>
		</div>
		<div class="p-2" style="background: #dee2e6;">
			<template v-if="(interestData!=null || interestData)">
				<span class="badge badge-primary rounded p-2 m-1" v-for="interest in interestData">
					{{ interest.name }}&nbsp;<i class="fas fa-times" @click="removeInterest(interest)"></i>
				</span>
			</template>

			<p v-else class="text-muted text-center mb-0">No interest found</p>
		</div>

		<template v-if="isAddInterest">
			<hr />
			<multiselect v-model="applicantInterests" :options="jobclass" :multiple="true" group-values="sub_classes" group-label="name" 
					:close-on-select="false" :group-select="true" placeholder="Search interests to add" track-by="name" label="name">
					 <span slot="noResult">Oops! No elements found..</span>
			</multiselect>
			<button @click="saveInterests" class="btn btn-sm btn-success mt-1"><i class="fas fa-save"></i> SAVE</button>
		</template>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import Multiselect from 'vue-multiselect'
	export default {
		name: 'applicant-interests',
		props: ['recordID', 'interestData'],
		components: {
			Multiselect
		},
		data() {
			return {
				isAddInterest: false,
				applicantInterests: [],
				currentInterests: []
			}
		},
		computed: {
			...mapGetters(['jobclass', 'app_interests']),
		},
		created() {
			this.$store.dispatch('fetchJobClass');
		},
		methods: {
			is_addInterest() {
				this.applicantInterests = [];
				this.isAddInterest = !this.isAddInterest;
			},
			removeInterest(selectedInterest) {
				let i = this.interestData.map(item => item.id).indexOf(selectedInterest.id);
				this.interestData.splice(i, 1);
			},
			saveInterests() {

				let currentObject = this;
				let saveData = [];
				saveData = { 'app_id': this.recordID, 'interests': JSON.stringify(this.interestData) }

				if(this.interestData.length > 0) {
					this.$store.dispatch('updateApplicantInterests', saveData);
					this.$swal.fire({
						position: 'top-end',
						icon: 'success',
						title: 'Record updated successfully',
						showConfirmButton: false,
						timer: 1500
					});
				} 
				else {
					this.$swal.fire({
					  icon: 'error',
					  title: 'Oops...',
					  text: 'Interest must not be empty',
					});
				}
				this.applicantInterests.forEach( function(item) {
					currentObject.interestData.push({'id':item.id, 'name':item.name})
				});
				this.applicantInterests = [];
			},
		}
	}
</script>
<style scoped></style>