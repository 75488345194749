<template>
	<div>
        <nav class="navbar navbar-expand-lg pr-1">
            <button style="opacity: 0.55 !important;" class="navbar-toggler bg-white rounded-0" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
                <ul class="navbar-nav mr-auto navCustomStyle">
                    <li class="nav-item mr-3">
                        <router-link class="nav-link text-uppercase pr-0 pl-0 pb-0 pt-1" :to="{ path: '/' }">
                            <span style="font-size: 21px !important;"><i class="fas fa-home"></i></span>
                        </router-link>
                    </li>
                    <li class="nav-item mr-3">
                        <router-link class="nav-link text-uppercase" :to="{ path: '/traineeship-programmes' }" :class="[$route.fullPath=='/traineeship-programmes' ? 'custom_nav_css' : '']">
                            Traineeship Programmes
                        </router-link>
                    </li>
                    <li class="nav-item mr-3">
                        <router-link class="nav-link text-uppercase" :to="{ path: '/organization-profiles' }" :class="[$route.fullPath=='/organization-profiles' ? 'custom_nav_css' : '']">
                            Organisation Profiles
                        </router-link>
                    </li>
                    <li class="nav-item mr-3">
                        <router-link class="nav-link text-uppercase" :to="{ path: '/news-events' }" :class="[($route.fullPath=='/news-events' || $route.fullPath=='/' + $route.params.type + '/'+ $route.params.slug) ? 'custom_nav_css' : '']">
                            News & Events
                        </router-link>
                    </li>
                   <!--  <li class="nav-item mr-3">
                        <a class="nav-link text-uppercase" href="#">Testimonials</a>
                    </li> -->
                    <li class="nav-item">
                        <router-link class="nav-link text-uppercase" :to="{ path: '/contact-us' }" :class="[$route.fullPath=='/contact-us' ? 'custom_nav_css' : '']">Contact Us</router-link>
                    </li>
                </ul>
                <div class="dropdown">
                    <router-link v-if="currentUser" class="btn btn-dark rounded-0 btnLogin" :to="{ path: '/dashboard' }">
                        DASHBOARD
                    </router-link>
                    <template v-else>
                        <button class="btn btn-dark rounded-0 dropdown-toggle btnLogin" type="button" data-toggle="dropdown">
                            LOG IN AS<span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu loginBtn">
                            <li class="text-center">
                                <a href="/login">APPLICANT</a>
                            </li>
                            <li class="text-center">
                                <a href="https://employer.streamsoflife.sg">EMPLOYER</a>
                            </li>
                        </ul>
                    </template>
                </div>
            </div>
        </nav>
	</div>
</template>
<script>
	export default {
		name: "navigation",

        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            }
        },

	}
</script>
<style scope>
	
	.navCustomStyle li {
		letter-spacing: 0.05em;
	}

	.navCustomStyle li a {
		font-size: 13px !important;
		font-weight: lighter !important;
		color: #fff;
	}

	.custom_nav_css {
        font-weight: bolder !important;
        color: #d2232a !important;
        background: #fff !important;
    }

    .dropdown-menu {
        min-width: 8rem !important;
    }

    .dropdown-menu li {
        padding: 5px !important;
        font-weight: bold !important;
    }

    .loginBtn li a {
        font-size: 13px !important;
        font-weight: lighter !important;
        color: #000;
    }
    .btnLogin {
        font-size: 13px !important;
        font-weight: lighter !important;
        color: #fff;
    }
</style>