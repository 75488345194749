<template>
	<div>
		<div class="d-lg-flex half">
			<div class="bg order-1 order-md-2" style="background-image: url('../images/bg_1.jpg');"></div>
			<div class="contents order-2 order-md-1">
				<div class="container">
					<div class="row align-items-center justify-content-center">
						<div class="col-md-7">
							<img src="/images/logo.png" width="100%">
							<p class="my-4">
								The Christian Ministry Traineeship Programme allows graduates in the Christian community and people who are looking for traineeship positions in Christian organisations.
							</p>
							<p>
								Please login your account or 
								<router-link :to="{ path: '/' }">go back to homepage</router-link>
                			</p>
							<div v-if="authError || isInactive" class="alert alert-danger alert-dismissible fade show" role="alert">
								<template v-if="authError">
									<small><strong>Oops!</strong> {{ authError }}</small>
								</template>
								<template v-if="isInactive">
									<small>
										<strong>Unauthorized!</strong><br />
										{{ inActiveMgs }}
									</small>
								</template>
								<button type="button" class="close" data-dismiss="alert" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<form @submit.prevent="authenticate">
								<div class="form-group first" :class="{'errorClass': authError}">
									<label for="username">Username</label>
									<input type="email" class="form-control" v-model="form.email" :class="{'is-invalid': authError}" placeholder="your-email@gmail.com" id="username">
								</div>
								<div class="form-group last mb-3" :class="{'errorClass': authError}">
									<label for="password">Password</label>
									<input type="password" v-model="form.password" :class="{'is-invalid': authError}" class="form-control" placeholder="Your Password" id="password">
								</div>
								<p>
									Not a member yet? <router-link :to="{ path: '/register' }">Sign up here</router-link>
	                			</p>
								<input type="submit" value="Log In" class="btn btn-block btn-primary">
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {login} from '../../helpers/auth';
	import { mapGetters } from 'vuex'

	export default {
		name: "login",
		data() {
			return {
				form: {
					email: null,
					password: null
				},
				isInactive: false,
				inActiveMgs: null,
			}
		},

		methods: {
			authenticate() {
				this.$store.dispatch('login');

				this.isInactive = false;

				login(this.$data.form)
					.then((res) => {
						if(res.status=='inactive') {
							this.isInactive = true;
							this.inActiveMgs = "User is not activated. Please contact admnistrator.";
						} 
						else {
							this.$store.commit('LOGIN_SUCCESS', res);
							this.$router.push('/dashboard');
						}
					})
					.catch((error) => {
						this.$store.commit('LOGIN_FAILED', {error})
					})
			}
		},
		computed: {
			...mapGetters(['authError'])
		}
	}
</script>

<style scoped>
	p {
		color: #b3b3b3;
		font-weight: 300; 
	}
	a {
		-webkit-transition: .3s all ease;
		-o-transition: .3s all ease;
		transition: .3s all ease; 
	}
	a:hover {
	    text-decoration: none !important;
	}

	.content {
		padding: 7rem 0;
	}

	h2 {
		font-size: 20px;
	}

	.half, .half .container > .row {
		height: 100vh;
	}

	@media (max-width: 991.98px) {
		.half .bg {
			height: 500px;
		}
	}

	.half .contents, .half .bg {
		width: 50%;
	}

	@media (max-width: 1199.98px) {
		.half .contents, .half .bg {
			width: 100%;
		}
	}
	.half .contents .form-group, .half .bg .form-group {
		margin-bottom: 0;
		border: 1px solid #efefef;
		padding: 15px 15px;
		border-bottom: none;
	}
	.half .contents .form-group.first, .half .bg .form-group.first {
		border-top-left-radius: 7px;
		border-top-right-radius: 7px; 
	}

	.half .contents .form-group.last, .half .bg .form-group.last {
		border-bottom: 1px solid #efefef;
		border-bottom-left-radius: 7px;
		border-bottom-right-radius: 7px;
	}

	.half .contents .form-group label, .half .bg .form-group label {
		font-size: 12px;
		display: block;
		margin-bottom: 0;
		color: #b3b3b3; 
	}

	.half .contents .form-control, .half .bg .form-control {
		border: none;
		padding: 0;
		font-size: 20px;
		border-radius: 0; 
	}

	.half .contents .form-control:active, .half .contents .form-control:focus, .half .bg .form-control:active, .half .bg .form-control:focus {
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	}

	.half .bg {
		background-size: cover;
		background-position: center;
	}

	.half a {
		color: #888;
		text-decoration: underline;
	}

	.half .btn {
		height: 54px;
		padding-left: 30px;
		padding-right: 30px;
	}
	/*.custom-margin {
		margin-top:200px !important;
	}
	.login-title {
		padding: 50px 0 10px 0;
		font-size: 25px;
	}*/

	.errorClass {
		border: 1px solid #f8d7da !important;
	}
</style>