<template>
	<div>
		<div class="card">
			<div class="card-header">
				<h3 class="card-title mt-2">APPLICANT MASTERLIST</h3>
				<div class="card-tools" style="rigth:0rem !important;">
					<div class="input-group input-group-sm mt-2" style="width: 300px;">
						<input type="text" v-model="filters.name.value" class="form-control form-control-sm" placeholder="Search applicant" aria-label="Search applicant" aria-describedby="basic-addon1">
						<div class="input-group-append bg-primary">
							<span class="input-group-text bg-primary" id="basic-addon1"><i class="fas fa-search"></i></span>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body table-responsive p-0" style="height: 648px;">
				<v-table :data="applicants" class="table table-head-fixed table-bordered table-hover" :currentPage.sync="currentPage" 
					:pageSize="10" @totalPagesChanged="totalPages = $event" :filters="filters">
					<thead slot="head">
						<tr>
							<th>Name</th>
							<th>Position Applied</th>
							<th>Date Applied</th>
							<th>Status</th>
							<th>Phone Number</th>
							<th>Email Address</th>
							<th width="2%">&nbsp;</th>
						</tr>
					</thead>
					<tbody slot="body" slot-scope="{displayData}">
						<template v-if="applicants.length==0">
							<tr>
								<td colspan="7" class="text-center py-4">
									<center><Stretch></Stretch></center>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr v-for="applicant in displayData" :key="applicant.id">
								<td class="align-middle">
									<a :href='/applicant/+applicant.applicantID'>
										<img :src="'/images/default_user.png'" class="img-circle photo_img" />
										<small>{{ applicant.lastname }}, {{ applicant.firstname }}</small>
									</a>
								</td>
								<td class="align-middle">
									<a :href='/job/+applicant.slug'>
										<small>{{ applicant.title }}</small>
									</a>
								</td>
								<td class="align-middle">
									<small>{{ dateFormatter(applicant.created_at) }} <i>({{ dateCounter(applicant.created_at) }})</i></small>
								</td>
								<td class="align-middle">
									<span class="badge badge-pill badge-secondary">{{ applicant.status }}</span>
								</td>
								<td class="align-middle"><small><i class="fas fa-phone text-muted"></i> {{ applicant.phone }}</small></td>
								<td class="align-middle"><small><i class="fas fa-envelope text-muted"></i> {{ applicant.email }}</small></td>
								<td class="text-right align-middle">
									<router-link :to="`/application/${applicant.id}`" class="text-primary">
										<i class="fas fa-arrow-right"></i>
									</router-link>
								</td>
							</tr>
							<tr v-if="displayData.length==0 || filters.name.length==0">
								<td colspan="7" class="text-center">No applicants found</td>
							</tr>
						</template>
					</tbody>
				</v-table>
			</div>
		</div>
		<smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" class="float-right" />
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import {Stretch} from 'vue-loading-spinner'
	import moment from 'moment'

	export default {
		name: 'applicants-list',
		components: {
			Stretch
		},
		data() {
			return {
				currentPage: 1,
				totalPages: 0,
				filters: {
					name: { value: '', keys: ['firstname', 'lastname', 'title', 'email', 'phone'] }
				}
			}
		},

		mounted() {
			this.$store.dispatch('fetchMyApplicants');
		},

		computed: {
			...mapGetters(['applicants'])
		},

		methods: {

			dateFormatter(data) {
				return moment(data).format("MMM DD, YYYY");
			},
			dateCounter(data) {
				return moment(data,"YYYYMMDD").fromNow();
			},
		}

	}
</script>

<style scoped>
	.photo_img {
		object-fit: cover;
		width: 32px;
		height: 32px;
	}
</style>