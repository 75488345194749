<template>
	<div>
        <footer class="footer">
            <div class="row justify-content-center pt-2 text-white" style="background-color: #252525 !important; margin-top: 30px;">
                <div class="col-md-10">
                    <nav class="navbar navbar-expand-lg">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <router-link class="nav-link text-uppercase text-white" :to="{ path: '/contact-us' }"><small>Connect with us</small></router-link>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-uppercase text-white" href="mailto:traineeship@streamsoflife.com.sg?Subject=CMTP%20Advertise%20Inquiry" target="_top"><small>Advertise with us</small></a>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link text-uppercase text-white" :to="{ path: '/privacy-policy' }"><small>Privacy Policy</small></router-link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </footer>
	</div>
</template>
<script>
	export default {
		name: "page-footer"
	}
</script>
<style scoped>
	.nav-item a {
       font-size: 14px !important;
    }
    .navbar-nav {
        margin:auto;
    }
</style>