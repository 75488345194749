require('./bootstrap');
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import {routes} from './routes';
import StoreData from './store';
import MainApp from './components/MainApp.vue';
import {initialize} from './helpers/init';
import SmartTable from 'vuejs-smart-table'

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import VeeValidate from 'vee-validate';

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
	hideModules: {
		image: true,
		table: true,
		code: true
	}
});

Vue.use(VeeValidate);
Vue.use(VueSweetalert2);
Vue.use(SmartTable)
Vue.use(VueRouter);
Vue.use(Vuex);

const store = new Vuex.Store(StoreData)

const router = new VueRouter ({
	routes,
	mode: 'history',
    linkExactActiveClass: 'active',
});

initialize(store, router);

const app = new Vue({
	el: '#app',
	router,
	store,
	components: {
		MainApp
	}
});