<template>
	<div>
		<template v-if="job.length==0">
			<div class="row">
				<div class="col-md-12">
					<center><Stretch></Stretch></center>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="row">
				<div class="col-md-12">
					<single-job :theJob="job"></single-job>
				</div>
			</div>
		</template>
	</div>
</template>
<script>

	import { mapGetters } from 'vuex'
	import {Stretch} from 'vue-loading-spinner'
	import SingleJob from '../jobs/SingleJob'

	export default {
		name: 'view-job',
		components: {
			Stretch,
			SingleJob
		},
		data() {
			return {
				job: [],
				uploadPath: '/images/uploads/',
			}
		},
		created() {
			this.fetchSelectedJob();
		},
		computed: {
			...mapGetters(['sectors']),
			currentUser() {
				return this.$store.getters.currentUser;
			},
		},
		methods: {
			async fetchSelectedJob() {
				let queryParam = {
					'employer_id': this.currentUser.employer_id,
					'slug' : this.$route.params.slug
				}
				await axios.get('/api/job/'+this.$route.params.slug, { 
							headers: { "Authorization": `Bearer ${this.currentUser.token}` }
						})
						.then((response) => {
							if(response.data.job.employer_id==this.currentUser.employer_id) {
								this.job = response.data.job;
							} 
							else {
								this.$swal.fire({
									position: 'center',
									icon: 'error',
									title: 'Oops..PAGE NOT FOUND',
									timer: 1500
								});
								setTimeout(() => this.$router.push('/404'), 1500);
							}

						})
						.catch((error) => {
							console.log(error);
						})
			},
		}
		
	}
</script>
<style scoped>
	.photo_img {
		object-fit: cover;
		width: inherit;
	}
</style>