<template>
    <div>
        <div class="container-fluid">
            <div class="row justify-content-center mt-4">
                <div class="col-md-10">
                    <div class="site_title clearfix text-center" style="padding: 60px;">
                        <img src="/images/logo.png" class="img_logo pr-2" style="width: 75%;">
                    </div>
                </div>
            </div>

            <!-- navigation  -->
            <div class="row justify-content-center nav_bg">
                <div class="col-md-10">
                    <Navigation></Navigation>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-9">
                    <div class="row">
                         <div class="col-md-12 custom-margin">
                            <h4 class="mb-4">{{ newsTitle }}</h4>
                            <br />
                            <p>
                                Dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </div>
                      
                    </div>
                </div>
            </div>
            
            <!-- footer -->
            <pageFooter></pageFooter>
        </div>
    </div>
</template>
<script>
    import Navigation from '../../layout/Navigation';
    import pageFooter from '../../layout/Footer';

    import moment from 'moment';

    export default {
        name: "single-news-events",
        components: {
            Navigation,
            pageFooter
        },
        data() {
            return {
                newsTitle: null
            }
        },
        created() {
          this.newsTitle = this.$route.params.slug;
        },
        methods: {
            dateFormatter(data) {
                return moment(data).format("MMM DD, YYYY");
            },
            dateCounter(data) {
                return moment(data,"YYYYMMDD").fromNow();
            }
        }

    }

</script>
<style scoped>
    .custom-margin {
        padding: 25px 15px 40px 15px;
    }
</style>