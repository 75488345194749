<template>
	<div>
		<div class="jumbotron py-4">
			<div class="row">
				<div class="col-md-2">
					<label class="typo__label">&nbsp;</label>
					<multiselect v-model="search.searchType" 
						:options="typeOfSearch" 
						:searchable="false" 
						:close-on-select="true" 
						:show-labels="true" placeholder="Search option">
					</multiselect>
				</div>
				<div :class="{ 'col-md-4': search.searchType!='Employer', 'col-md-5': search.searchType=='Employer' }">
					<label class="typo__label">&nbsp;</label>
					<input v-model="search.keyword" type="text" placeholder="Keyword: Employer's name/Job title/Applicant's name" 
						class="form-control custom-input-form">
				</div>
				<template v-if="search.searchType!='Employer'">
					<div class="col-md-3">
						<label class="typo__label">&nbsp;</label>
						<multiselect v-model="search.jobClass" 
							:options="jobclass" 
							:searchable="false" 
							:close-on-select="true" 
							label="name" 
							track-by="name"
							:show-labels="true" placeholder="Job classification">
						</multiselect>
					</div>
					<div class="col-md-3">
						<label class="typo__label">&nbsp;</label>
						<multiselect v-model="search.jobSubClass" 
							:options="displayCurrentJobClasses" 
							:searchable="false" 
							label="name"
							track-by="name"
							:close-on-select="true" 
							:show-labels="true" placeholder="Job sub-class">
						</multiselect>
					</div>
				</template>
				<template v-if="search.searchType=='Employer'">
					<div class="col-md-5">
						<label class="typo__label">&nbsp;</label>
						<multiselect v-model="search.companySector" 
							:options="sectors" 
							:searchable="false" 
							:close-on-select="true" 
							label="name"
							track-by="name"
							:show-labels="true" placeholder="Industry/sector">
						</multiselect>
					</div>
				</template>
			</div>
			<div class="row mt-2">
				<div class="col-md-2">
					<button @click.prevent="goSearch()" class="btn btn-primary"><i class="fas fa-search"></i> SEARCH</button>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
	import { mapGetters } from 'vuex'
	import Multiselect from 'vue-multiselect'
	export default {
		name: 'search-records',
		components: {
			Multiselect
		},
		data() {
			return {
				typeOfSearch: ['Job Application', 'Employer', 'Applicant'],
				jobSubClass: [],
				isEmployer: false,
				isApplicant: false,
				search: {
					searchType: 'Job Application',
					keyword: null,
					jobClass: null,
					jobSubClass: null,
					companySector: null
				},
			}
		},
		created() {
			this.$store.dispatch('fetchSectors');
			this.$store.dispatch('fetchJobClass');
			this.$store.dispatch('fetchSectors');

			this.searchType = this.searchType==null ? 'Employer' : this.searchType;
			this.search.jobClass = this.$store.getters.jobclass;
		},
		computed: {
			...mapGetters(['sectors', 'jobclass', 'sectors']),
			currentUser() {
				return this.$store.getters.currentUser;
			},
			displayCurrentJobClasses() {
				let currentObject = this;
				this.jobSubClass = [];
				this.$store.getters.jobclass.forEach( function(item) {
					if(item.name==currentObject.search.jobClass.name) {
						currentObject.jobSubClass = item.sub_classes;
					}
				})
				return this.jobSubClass;
			},

		},
		methods: {
			goSearch() {
				console.log(this.search);
			},
		}
	}

</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
	.custom-input-form {
		height: 40px !important;
	}
</style>