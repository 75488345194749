<template>
	<div>
		<aside class="main-sidebar sidebar-dark-primary elevation-4">
			<a href="/" class="brand-link">
				<img src="http://placehold.jp/60x60.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
				<span class="brand-text font-weight-light">Traineeship Portal</span>
			</a>
			<div class="sidebar">
				<div class="user-panel mt-3 pb-3 mb-3 d-flex">
					<div class="image">
						<img :src="currentUser.profile_pic ? currentUser.profile_pic : '/images/default_user.png' " class="img-circle elevation-2" alt="User Image">
					</div>
					<div class="info">
						<a href="#" class="d-block"><strong>{{ currentUser.nice_name }}</strong></a>
					</div>
				</div>
				<nav class="mt-2">
					<ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
						<li class="nav-item">
		                    <router-link class="nav-link" :to="{ path: '/dashboard' }">
		                        <i class="fas fa-tachometer-alt nav-icon"></i>
		                        <p>Dashboard</p>
		                    </router-link>
		                </li>
		                <li class="nav-item">
		                    <router-link class="nav-link" :to="{ path: '/employer/'+currentUser.employer_id }" >
		                        <i class="fas fa-file-invoice nav-icon"></i>
		                        <p>My Company</p>
		                    </router-link>
		                </li>
		                <li class="nav-item">
		                    <router-link class="nav-link" :to="{ path: '/jobs' }" :class="[($route.fullPath=='/job/'+ $route.params.slug || $route.fullPath=='/employer/'+ $route.params.id+'/job/new' || $route.fullPath=='/application/'+ $route.params.id) ? 'active' : '']">
		                        <i class="fas fa-server nav-icon"></i>
		                        <p>My Jobs</p>
		                    </router-link>
		                </li>
		                <li class="nav-item">
		                    <router-link class="nav-link" 
		                    	:to="{ path: '/applicants' }"
		                    	:class="[($route.fullPath=='/applicant' || $route.fullPath=='/applicant/'+ $route.params.id) ? 'active' : '']">
		                        <i class="fas fa-users nav-icon"></i>
		                        <p>My Applicants</p>
		                    </router-link>
		                </li>
		                <li class="nav-item">
		                    <router-link class="nav-link" :to="{ path: '/user/' + currentUser.id }" :class="[$route.fullPath=='/user/'+ $route.params.id ? 'active' : '']">
		                        <i class="fas fa-user nav-icon"></i>
		                        <p>User Profile</p>
		                    </router-link>
		                </li>
		                <li class="nav-item">
		                    <a href="" @click.prevent="logout()" class="nav-link">
		                        <i class="fas fa-power-off nav-icon"></i>
		                        <p>Log Out</p>
		                    </a>
		                </li>
					</ul>
				</nav>
			</div>
		</aside>
	</div>

<!-- 	:class="[($route.fullPath=='/employer/new' || $route.fullPath=='/employers' || $route.fullPath=='/employer/'+ $route.params.id || $route.fullPath=='/employer/'+ $route.params.id+'/jobs' || $route.fullPath=='/employer/'+ $route.params.id+'/job/new') ? 'active' : '']" -->

</template>

<script>
	export default {
		name: 'sidebar',
		data() {
			return {
				uploadPath: '/images/uploads/'
			}
		},

		methods: {
			logout() {
				this.$store.dispatch('logout');
				this.$router.push('/login');
			},
		},

		computed: {
			currentUser() {
				return this.$store.getters.currentUser;
			}
		},
		created() {
		}
	}
</script>